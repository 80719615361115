import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { SET_LOADER } from "../../store/formSlice";
import { VALIDATE_USER_DATA } from "../../utility/helper";
import { createOrg } from "../../api/organisation";
import { AiOutlinePlus, AiOutlineCheck } from "react-icons/ai";

const CreateOrg = () => {
  const navigate = useNavigate();

  const [image, setImage] = React.useState<File | null>(null);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const dispatch = useDispatch();

  const validate = () => {
    VALIDATE_USER_DATA({ name, description }, (e) => {
      dispatch(SET_LOADER(true));
      createOrg(
        { name, description, image },
        (response) => {
          dispatch(SET_LOADER(false));
          toast.success(response.message);
          navigate("/signin");
        },
        () => dispatch(SET_LOADER(false))
      );
    });
  };
  return (
    <>
      <div className="form-wrapper">
        <div className="app-form">
          <div className="app-form-sidebar">
            <div className="sidebar-sign-logo">
              <img src="/images/sign-logo.svg" alt="" />
            </div>
            <div className="sign_sidebar_text">
              <h1>
                The Easiest Way to Create Events and Sell More Tickets Online
              </h1>
            </div>
          </div>
          <div className="app-form-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-10">
                  <div className="app-top-items">
                    <Link to="/">
                      <div className="sign-logo" id="logo">
                        <img src="/images/logo.svg" alt="" />
                        <img
                          className="logo-inverse"
                          src="/images/dark-logo.svg"
                          alt=""
                        />
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-7">
                  <div className="registration">
                    <form>
                      <h2 className="registration-title">
                        Create Organisation Account
                      </h2>
                      <div className="form-group mt-5">
                        <div className="w-full mb-8 ">
                          <div className="max-w-[400px] rounded-md mx-auto h-[150px] bg-[#F9F9F9BF] mt-2 flex justify-center items-center relative">
                            <div className="flex flex-col items-center">
                              <div className="w-[50px] h-[50px] flex justify-center items-center bg-white rounded-full shadow-md">
                                {image ? (
                                  <AiOutlineCheck color="#004F5D" size={25} />
                                ) : (
                                  <AiOutlinePlus color="#004F5D" size={25} />
                                )}
                              </div>
                              <p className="font-Nunito text-darkGrey text-[14px] mt-[10px]">
                                {image
                                  ? "Logo uploaded Successfully"
                                  : "Upload Organization Logo"}
                              </p>
                            </div>
                            <input
                              type="file"
                              onChange={(e) =>
                                setImage(e.target.files && e.target.files[0])
                              }
                              className="absolute top-0 left-0 w-full h-full opacity-0"
                            />
                          </div>
                        </div>
                        <label className="form-label">Organisation Name*</label>
                        <input
                          className="form-control h_50 focus:!border-primary"
                          type="text"
                          placeholder="Enter your Organisation Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="form-group mt-4">
                        <label className="form-label">Description*</label>
                        <textarea
                          className="form-control focus:!border-primary py-3"
                          placeholder="Enter your Description"
                          rows={7}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                      </div>
                      <button
                        className="main-btn btn-hover w-100 mt-4 text-[#004F5D] transition-all hover:text-white"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          validate();
                        }}
                      >
                        Finish
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright-footer">
              © 2024, Usepuplar. All rights reserved. Powered by Akawo Synergy
              Limited
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOrg;
