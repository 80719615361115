import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export const LineChart = ({ data }: { data: any }) => {
  return (
    <ResponsiveContainer>
      <AreaChart
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" fontSize={13} />
        <YAxis fontSize={13} />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="value"
          stroke="#41C0BA"
          fill="#EDFFFE"
          activeDot={{ fill: "#ffffff", stroke: "#41C0BA" }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
