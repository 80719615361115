import React from "react";
import ReactLoading from "react-loading";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Signin from "./pages/auth/signin";
import Signup from "./pages/auth/signup";
import ForgotPassword from "./pages/auth/forgotpassword";
import Otp from "./pages/auth/otp";
import Dashboard from "./pages/dashboard";
import Event from "./pages/dashboard/event";
import Profile from "./pages/dashboard/profile";
import Team from "./pages/dashboard/team";
import Report from "./pages/dashboard/report";
import CreateEvent from "./pages/dashboard/createEvent";
import Transaction from "./pages/dashboard/transaction";
import EditEvent from "./pages/dashboard/editEvent";
import { RootState } from "./store";
import { useSelector } from "react-redux";
import CreateOrg from "./pages/auth/createOrg";
import CreatePassword from "./pages/auth/createPassword";
import { useFetchDetails } from "./hooks/useFetchDetails";
import { PublicRoute } from "./components/publicRoute";
import { PrivateRoute } from "./components/privateRoute";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App() {
  const { loader } = useSelector((state: RootState) => state.form);

  useFetchDetails();
  let query = useQuery();

  return (
    <div className="w-full h-screen relative">
      <Routes>
        <Route
          path="/signup"
          element={
            <PublicRoute redirectTo="/">
              <Signup email={query.get("email")} invite={query.get("key")} status={query.get("status")}/>
            </PublicRoute>
          }
        />
        <Route
          path="/signin"
          element={
            <PublicRoute redirectTo="/">
              <Signin />
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRoute redirectTo="/">
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/verify-otp"
          element={
            <PublicRoute redirectTo="/">
              <Otp />
            </PublicRoute>
          }
        />
        <Route
          path="/create-org"
          element={
            // <PublicRoute redirectTo="/">
            // </PublicRoute>
              <CreateOrg />
          }
        />
        <Route
          path="/create-password"
          element={
            <PublicRoute redirectTo="/">
              <CreatePassword />
            </PublicRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute redirectTo="/signin">
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/create-event"
          element={
            <PrivateRoute redirectTo="/signin">
              <CreateEvent />
            </PrivateRoute>
          }
        />
        <Route
          path="/event"
          element={
            <PrivateRoute redirectTo="/signin">
              <Event />
            </PrivateRoute>
          }
        />
        <Route
          path="/event/:id"
          element={
            <PrivateRoute redirectTo="/signin">
              <EditEvent />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/payout"
          element={
            <PrivateRoute redirectTo="/signin">
              <Payout />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/order"
          element={
            <PrivateRoute redirectTo="/signin">
              <Report />
            </PrivateRoute>
          }
        />
        <Route
          path="/payout"
          element={
            <PrivateRoute redirectTo="/signin">
              <Transaction />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute redirectTo="/signin">
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/team"
          element={
            <PrivateRoute redirectTo="/signin">
              <Team />
            </PrivateRoute>
          }
        />
      </Routes>
      {loader && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.7)", zIndex: 1000 }}
          className="w-full h-full fixed top-0 left-0 flex justify-center items-center z-70"
        >
          <div className="px-3 bg-white rounded-lg flex flex-col justify-center items-center overflow-y-auto py-3">
            {/* <CircularProgress size={25} /> */}
            <ReactLoading
              type={"spin"}
              color={"#004f5d"}
              height={25}
              width={25}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
