// import { store } from "../store";
import { toast } from "react-toastify";
import { API_URL as BASE_URL, MAX_API_RETRY } from "../utility/appConfig";
import axios from "axios";
import { refreshUserToken } from "./authentication";
import { store } from "../store";

let retryCount = 0;

export const createOrg = async (
  data: {
    image: File | null;
    name: string;
    description: string;
  },
  execute: (e: any) => void,
  error: () => void
) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("description", data.description);
  data.image && formData.append("image", data.image);
  var config = {
    method: "post",
    url: `${BASE_URL}/organization`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
    data: formData,
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          createOrg({ ...data }, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
      error();
    }
  }
};

export const getOrgProfile = async (
  id: string,
  execute: (e: any) => void,
  error: () => void
) => {
  var config = {
    method: "get",
    url: `${BASE_URL}/organization/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          getOrgProfile(id, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
      error();
    }
  }
};

export const getOrgTransactions = async (
  id: string,
  page: number,
  execute: (e: any) => void,
  error: () => void
) => {
  var config = {
    method: "get",
    url: `${BASE_URL}/organization/transaction/${id}?page=${page}`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          getOrgTransactions(id, page, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
      error();
    }
  }
};

export const getOrgOrders = async (
  id: string,
  page: number,
  execute: (e: any) => void,
  error: () => void
) => {
  var config = {
    method: "get",
    url: `${BASE_URL}/events/orders/organization/${id}?page=${page}`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          getOrgOrders(id, page, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
      error();
    }
  }
};

export const editOrg = async (
  id: string,
  data: {
    image: File | null;
    name: string;
    description: string;
  },
  execute: (e: any) => void,
  error: () => void
) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("description", data.description);
  data.image && formData.append("image", data.image);
  var config = {
    method: "put",
    url: `${BASE_URL}/organization/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
    data: formData,
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          editOrg(id, { ...data }, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
      error();
    }
  }
};

export const addUserToOrg = async (
  id: string,
  data: {
    email: string;
    role: string;
  },
  execute: (e: any) => void,
  error: () => void
) => {
  var config = {
    method: "post",
    url: `${BASE_URL}/organization/${id}/invite`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
    data: { ...data, callback_url: window.location.origin + "/signup" },
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          addUserToOrg(id, { ...data }, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
      error();
    }
  }
};

export const removeUserFromOrg = async (
  id: string,
  data: { invitation_id: string },
  execute: (e: any) => void,
  error: () => void
) => {
  var config = {
    method: "delete",
    url: `${BASE_URL}/organization/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
    data,
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          removeUserFromOrg(id, { ...data }, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
      error();
    }
  }
};


export const updateUserOrgStatus = async (
  data: { invitation_id: string, status: string; },
  execute: (e: any) => void,
  error: () => void
) => {
  var config = {
    method: "put",
    url: `${BASE_URL}/organization/invite`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
    data,
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          updateUserOrgStatus({ ...data }, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
      error();
    }
  }
};

export const getUsersInOrg = async (
  id: string,
  page: number,
  execute: (e: any) => void,
  error: () => void
) => {
  var config = {
    method: "get",
    url: `${BASE_URL}/organization/${id}/users?page=${page}`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          getUsersInOrg(id, page, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
      error();
    }
  }
};


export const deleteUsersInOrg = async (
  id: string,
  data: {invitation_id: string},
  execute: (e: any) => void,
  error: () => void
) => {
  var config = {
    method: "delete",
    url: `${BASE_URL}/organization/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
    data
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          deleteUsersInOrg(id, {...data}, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
      error();
    }
  }
};

export const withdrawInOrg = async (
  data: {
    organization_id: string;
    amount: number;
  },
  execute: (e: any) => void,
  error: () => void
) => {
  var config = {
    method: "post",
    url: `${BASE_URL}/organization/withdraw`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
    data,
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          withdrawInOrg({ ...data }, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
      error();
    }
  }
};
