import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IFormData {
  errorModal: boolean;
  errorMessage: string;
  loader: boolean;
  successModal: boolean;
  successMessage: string;
  cartLength: number;

  authData: {
    phoneNumber: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
  };
}

const initialState: IFormData = {
  errorModal: false,
  errorMessage: "",
  loader: false,
  cartLength: 0,
  successModal: false,
  successMessage: "",

  authData: {
    phoneNumber: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  },
};

export const formDataSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    SET_ERROR: (state, action: PayloadAction<string>) => {
      state.errorModal = true;
      state.errorMessage = action.payload;
    },
    UNSET_ERROR: (state) => {
      state.errorModal = false;
      state.errorMessage = "";
    },
    SET_SUCCESS: (state, action: PayloadAction<string>) => {
      state.successModal = true;
      state.successMessage = action.payload;
    },
    SET_CART_LENGTH: (state, action: PayloadAction<number>) => {
      state.cartLength = action.payload;
    },
    UNSET_SUCCESS: (state) => {
      state.successModal = false;
      state.successMessage = "";
    },
    SET_LOADER: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    SET_PHONE_NUMBER: (state, action: PayloadAction<string>) => {
      state.authData.phoneNumber = action.payload;
    },
    SET_FIRST_NAME: (state, action: PayloadAction<string>) => {
      state.authData.firstName = action.payload;
    },
    SET_LAST_NAME: (state, action: PayloadAction<string>) => {
      state.authData.lastName = action.payload;
    },
    SET_EMAIL: (state, action: PayloadAction<string>) => {
      state.authData.email = action.payload;
    },
    SET_PASSWORD: (state, action: PayloadAction<string>) => {
      state.authData.password = action.payload;
    },
    SET_CONFIRM_PASSWORD: (state, action: PayloadAction<string>) => {
      state.authData.confirmPassword = action.payload;
    },
  },
});

export const {
  SET_ERROR,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_CART_LENGTH,
  SET_LOADER,
  SET_PASSWORD,
  SET_CONFIRM_PASSWORD,
  SET_PHONE_NUMBER,
  SET_EMAIL,
  SET_SUCCESS,
  UNSET_ERROR,
  UNSET_SUCCESS,
} = formDataSlice.actions;

export default formDataSlice.reducer;
