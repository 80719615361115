import React from "react";
import Layout from "../../components/layout";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrgEvents } from "../../api/event";
import { useOptimizedEffect } from "../../hooks/useOptimizedEffect";
import { RootState } from "../../store";
import { SET_LOADER } from "../../store/formSlice";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/bootstrap.css";
import { getOrgTransactions, withdrawInOrg } from "../../api/organisation";
import { FirstLetterUppercase, TruncateString } from "../../utility/helper";
import moment from "moment";
import { toast } from "react-toastify";
import { SET_ORG_PROFILE } from "../../store/orgSlice";

const Withdraw = ({ callback }: { callback: () => void }) => {
  const dispatch = useDispatch();
  const { orgProfile, selectedOrg } = useSelector(
    (state: RootState) => state.org
  );
  const [amount, setAmount] = React.useState<string>("");

  const validate = () => {
    if (Number(amount) > (orgProfile?.balance || 0)) {
      toast.error("Insufficient Funds.");
    } else {
      dispatch(SET_LOADER(true));
      withdrawInOrg(
        {
          amount: Number(amount),
          organization_id: selectedOrg?.organization_id?._id || "",
        },
        (response) => {
          orgProfile &&
            dispatch(
              SET_ORG_PROFILE({
                ...orgProfile,
                balance: orgProfile.balance - Number(amount),
              })
            );
          toast.success(response.message);
          dispatch(SET_LOADER(false));
          callback();
        },
        () => dispatch(SET_LOADER(false))
      );
    }
  };
  return (
    <>
      {/* <!-- Organisation Profile Update Model Start--> */}
      <div
        className="modal fade"
        id="payout-profile-update-pop"
        tabIndex={-1}
        aria-labelledby="orgProfileUpdatepopLabel"
        aria-hidden="false"
      >
        <div className="modal-dialog modal-medium-2 modal-dialog-scrollable modal-dialog-centered modal-sm-height modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="orgProfileUpdatepopLabel">
                Organization Wallet Withdrawal
              </h5>
              <button
                type="button"
                className="close-model-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="uil uil-multiply"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="model-content main-form">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">Amount*</label>
                      <input
                        className="form-control h_40 focus:!border-primary"
                        type="number"
                        placeholder=""
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="co-main-btn min-width btn-hover h_40 transition-all"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                onClick={validate}
                className="main-btn min-width btn-hover h_40 transition-all text-[#004F5D] hover:text-white"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Organisation Profile Update Model End--> */}
    </>
  );
};

const Transaction = () => {
  const [transactions, setTransaction] = React.useState<any>(null);
  const [page, setPage] = React.useState<number>(1);
  const { selectedOrg, orgProfile } = useSelector(
    (state: RootState) => state.org
  );
  const dispatch = useDispatch();
  const ref = React.useRef<number>(0);

  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    getOrgTransactions(
      selectedOrg?.organization_id?._id || "",
      page,
      (response) => {
        dispatch(SET_LOADER(false));
        setTransaction(response.data);
        console.log(response.data);
      },
      () => dispatch(SET_LOADER(false))
    );
  }, [ref.current]);
  return (
    <>
      <Withdraw callback={() => (ref.current = ref.current + 1)} />
      <Layout />
      {/* <!-- Body Start --> */}
      <div className="wrapper wrapper-body">
        <div className="dashboard-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="d-main-title">
                  <h3>
                    <i className="fa-solid fa-money-bill-trend-up me-3"></i>
                    Payouts
                  </h3>
                </div>
              </div>
              <div className="col-md-12">
                <div className="main-card mt-5">
                  <div className="dashboard-wrap-content p-4">
                    <div className="d-md-flex flex-wrap align-items-center">
                      <div className="dashboard-date-wrap mt-4">
                        <div className="form-group">
                          <p>
                            Available Balance:{" "}
                            <span className="text-primary font-semibold">
                              ₦{orgProfile?.balance.toLocaleString()}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="rs ms-auto mt-4 mt_r4">
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#payout-profile-update-pop"
                          className="pe-4 w-100 ps-4 text-center co-main-btn h_40 d-inline-block"
                        >
                          <i className="fa-solid fa-money-bill-transfer me-3"></i>
                          Withdraw
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="event-list">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="orders-tab"
                      role="tabpanel"
                    >
                      <div className="table-card mt-4">
                        <div className="main-table">
                          <div className="table-responsive">
                            <table className="table">
                              <thead className="thead-dark">
                                <tr>
                                  <th scope="col">ID</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {transactions?.docs.map(
                                  (item: any, key: number) => (
                                    <tr key={key.toString()}>
                                      <td>{TruncateString(item._id)}</td>
                                      <td>₦{item.amount.toLocaleString()}</td>
                                      <td>
                                        {FirstLetterUppercase(item.status)}
                                      </td>
                                      <td>
                                        {moment(item.created_at).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                            <ResponsivePagination
                              current={page}
                              total={transactions?.totalPage || 1}
                              onPageChange={(current) => setPage(current)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Body End --> */}
    </>
  );
};

export default Transaction;
