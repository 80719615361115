import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import formDataReducer from "./formSlice";
import authReducer from "./authSlice";
import orgReducer from "./orgSlice"

const reducers = combineReducers({
  form: formDataReducer,
  auth: authReducer,
  org: orgReducer,
});

const persistConfig = {
  key: "state",
  storage,
  whitelist: ["auth", "org"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
