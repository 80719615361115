import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { createPassword } from "../../api/authentication";
import { SET_LOADER } from "../../store/formSlice";
import { validateConfirmPassword } from "../../utility/helper";

const CreatePassword = () => {
  const navigate = useNavigate();
  const [confirm_password, setConfirmPassword] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [visible, setVisible] = React.useState(false);
  const [visible1, setVisible1] = React.useState(false);
  const dispatch = useDispatch();

  const validate = () => {
    if (password.length < 6) {
      toast.error("Password must be 6 characters long or more.");
    } else if (!validateConfirmPassword(password, confirm_password)) {
      toast.error("Passwords do not match.");
    } else {
      dispatch(SET_LOADER(true));
      createPassword(
        { confirm_password, password },
        (response) => {
          dispatch(SET_LOADER(false));
          toast.success(response.message);
          navigate("/signin");
        },
        () => dispatch(SET_LOADER(false))
      );
    }
  };
  return (
    <>
      <div className="form-wrapper">
        <div className="app-form">
          <div className="app-form-sidebar">
            <div className="sidebar-sign-logo">
              <img src="/images/sign-logo.svg" alt="" />
            </div>
            <div className="sign_sidebar_text">
              <h1>
                The Easiest Way to Create Events and Sell More Tickets Online
              </h1>
            </div>
          </div>
          <div className="app-form-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-10">
                  <div className="app-top-items">
                    <Link to="/">
                      <div className="sign-logo" id="logo">
                        <img src="/images/logo.svg" alt="" />
                        <img
                          className="logo-inverse"
                          src="/images/dark-logo.svg"
                          alt=""
                        />
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-7">
                  <div className="registration">
                    <form>
                      <h2 className="registration-title">
                        Create New Password
                      </h2>
                      <div className="form-group mt-5">
                        <label className="form-label">
                          Enter New Password*
                        </label>
                        <div className="loc-group position-relative">
                          <input
                            className="form-control h_50 focus:!border-primary"
                            type={visible1 ? "text" : "password"}
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <span className="pass-show-eye">
                            {visible1 ? (
                              <i
                                onClick={() => setVisible1(!visible1)}
                                className="fas fa-eye-slash"
                              ></i>
                            ) : (
                              <i
                                onClick={() => setVisible1(!visible1)}
                                className="fas fa-eye"
                              ></i>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="form-group mt-4">
                        <label className="form-label">
                          Re-Enter your Password*
                        </label>
                        <div className="loc-group position-relative">
                          <input
                            className="form-control h_50 focus:!border-primary"
                            type={visible ? "text" : "password"}
                            placeholder="Re-Enter your password"
                            value={confirm_password}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          <span className="pass-show-eye">
                            {visible ? (
                              <i
                                onClick={() => setVisible(!visible)}
                                className="fas fa-eye-slash"
                              ></i>
                            ) : (
                              <i
                                onClick={() => setVisible(!visible)}
                                className="fas fa-eye"
                              ></i>
                            )}
                          </span>
                        </div>
                      </div>
                      <button
                        className="main-btn btn-hover w-100 mt-4 text-[#004F5D] transition-all hover:text-white"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          validate();
                        }}
                      >
                        Continue
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright-footer">
              © 2024, Usepuplar. All rights reserved. Powered by Akawo Synergy
              Limited
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePassword;
