import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  VALIDATE_USER_DATA,
  validateEmail,
  validatePhone,
} from "../../utility/helper";
import { toast } from "react-toastify";
import { register } from "../../api/authentication";
import { useDispatch } from "react-redux";
import { SET_LOADER } from "../../store/formSlice";
import { updateUserOrgStatus } from "../../api/organisation";

const Signup = ({
  email: inviteEmail,
  invite,
  status,
}: {
  invite: string | null;
  status: string | null;
  email: null | string;
}) => {
  const navigate = useNavigate();
  const [first_name, setFirstName] = React.useState("");
  const [last_name, setLastName] = React.useState("");
  const [email, setEmail] = React.useState(inviteEmail || "");
  const [phone_number, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [visible, setVisible] = React.useState(false);
  const dispatch = useDispatch();

  // console.log(invite, status, inviteEmail);

  const validate = () => {
    VALIDATE_USER_DATA({ first_name, last_name, email, phone_number }, (e) => {
      if (!validateEmail(email)) {
        toast.error("Email is invalid");
      } else if (!validatePhone(phone_number)) {
        toast.error("Phone number should be up to 11 characters");
      } else {
        dispatch(SET_LOADER(true));
        register(
          {
            first_name,
            last_name,
            email,
            password,
            dial_code: "234",
            phone_number,
            invitation_id: invite,
          },
          (response) => {
            toast.success(response.message);
            if (invite && inviteEmail && status) {
              updateUserOrgStatus(
                { invitation_id: invite, status: "accepted" },
                (response) => {
                  navigate("/verify-otp", {
                    state: {
                      type: "email",
                      email,
                      send: false,
                      goToCreate: false,
                    },
                  });
                  dispatch(SET_LOADER(false));
                },
                () => dispatch(SET_LOADER(false))
              );
            } else {
              navigate("/verify-otp", {
                state: { type: "email", email, send: false, goToCreate: true },
              });
              dispatch(SET_LOADER(false));
            }
          },
          () => {
            dispatch(SET_LOADER(false));
          }
        );
      }
    });
  };

  return (
    <>
      <div className="form-wrapper">
        <div className="app-form">
          <div className="app-form-sidebar">
            <div className="sidebar-sign-logo">
              <img src="/images/sign-logo.svg" alt="" />
            </div>
            <div className="sign_sidebar_text">
              <h1>
                The Easiest Way to Create Events and Sell More Tickets Online
              </h1>
            </div>
          </div>
          <div className="app-form-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-10">
                  <div className="app-top-items">
                    <Link to="/">
                      <div className="sign-logo" id="logo">
                        <img src="/images/logo.svg" alt="" />
                        <img
                          className="logo-inverse"
                          src="/images/dark-logo.svg"
                          alt=""
                        />
                      </div>
                    </Link>
                    <div className="app-top-right-link">
                      Already have an account?
                      <Link className="sidebar-register-link" to="/signin">
                        Sign In
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-7">
                  <div className="registration">
                    <form>
                      <h2 className="registration-title">Sign up to Puplar</h2>
                      <div className="row mt-3">
                        <div className="col-lg-6 col-md-12">
                          <div className="form-group mt-4">
                            <label className="form-label">First Name*</label>
                            <input
                              className="form-control h_50 focus:!border-primary"
                              type="text"
                              placeholder=""
                              value={first_name}
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="form-group mt-4">
                            <label className="form-label">Last Name*</label>
                            <input
                              className="form-control h_50 focus:!border-primary"
                              type="text"
                              placeholder=""
                              value={last_name}
                              onChange={(e) => setLastName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group mt-4">
                            <label className="form-label">Your Email*</label>
                            <input
                              className="form-control h_50 focus:!border-primary"
                              type="email"
                              placeholder=""
                              disabled={inviteEmail ? true : false}
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group mt-4">
                            <label className="form-label">
                              Your Phone Number*
                            </label>
                            <input
                              className="form-control h_50 focus:!border-primary"
                              type="tel"
                              placeholder=""
                              value={phone_number}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group mt-4">
                            <div className="field-password">
                              <label className="form-label">Password*</label>
                            </div>
                            <div className="loc-group position-relative">
                              <input
                                className="form-control h_50 focus:!border-primary"
                                type={visible ? "text" : "password"}
                                placeholder=""
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <span className="pass-show-eye">
                                {visible ? (
                                  <i
                                    onClick={() => setVisible(!visible)}
                                    className="fas fa-eye-slash"
                                  ></i>
                                ) : (
                                  <i
                                    onClick={() => setVisible(!visible)}
                                    className="fas fa-eye"
                                  ></i>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              validate();
                            }}
                            className="main-btn btn-hover w-100 mt-4 text-[#fffffff] transition-all hover:text-white"
                          >
                            Sign Up
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="agree-text mb-5">
                      By clicking "Sign up", you agree to Usepuplar{" "}
                      <a
                        href="https://puplar.com/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Terms & Conditions
                      </a>{" "}
                      and have read the{" "}
                      <a
                        href="https://puplar.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                      .
                    </div>
                    <div className="new-sign-link">
                      Already have an account?
                      <Link className="signup-link" to="/signin">
                        Sign In
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright-footer">
              © 2024, Usepuplar. All rights reserved. Powered by Akawo Synergy
              Limited
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
