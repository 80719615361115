import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IOrg } from "./authSlice";

interface OrgProfile {
  _id: string;
  user_id: string;
  name: string;
  description: string;
  commission: number;
  balance: number;
  image: Image;
  is_approved: boolean;
  created_at: string;
  updated_at: string;
  __v: number;
}

interface Image {
  _id: string;
  name: string;
  url: string;
  public_id: string;
  created_at: string;
  updated_at: string;
  __v: number;
  uploaded_by: string;
}

interface IOrgProfile {
  orgProfile: OrgProfile | null;
  selectedOrg: IOrg | null;
  selectedEvent: any;
  orgEvents: any[]
}

const initialState: IOrgProfile = {
  orgProfile: null,
  selectedOrg: null,
  selectedEvent: null,
  orgEvents: []
};

export const orgSlice = createSlice({
  name: "org",
  initialState,
  reducers: {
    SET_ORG_PROFILE: (
      state,
      action: PayloadAction<OrgProfile | null>
    ) => {
      state.orgProfile = action.payload
        ? { ...state.orgProfile, ...action.payload }
        : null;
    },
    SET_SELECTED_ORG: (state, action: PayloadAction<any | null>) => {
      state.selectedOrg = action.payload
    },
    SET_ORG_EVENTS: (
      state,
      action: PayloadAction<any[]>
    ) => {
      state.orgEvents = action.payload
    },
    SET_SELECTED_EVENT: (state, action: PayloadAction<any | null>) => {
      state.selectedEvent = action.payload
    },
  },
});

export const { SET_ORG_PROFILE, SET_SELECTED_ORG, SET_ORG_EVENTS, SET_SELECTED_EVENT } = orgSlice.actions;

export default orgSlice.reducer;
