import React from "react";
import Layout from "../../components/layout";
import {
  CreateTicket,
  EditTicketSection,
} from "../../components/editTicketSection";
import EditEventSection from "../../components/editEventSection";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SET_LOADER } from "../../store/formSlice";
import { getEventDetail } from "../../api/event";

const EditEvent = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [section, setSection] = React.useState<"event" | "ticket">("event");
  const [eventDetails, setEventDetails] = React.useState<any>(null);
  const [ticketList, setTicketList] = React.useState<any[]>([]);
  const [ticketModal, setTicketModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    getEventDetail(
      id || "",
      (response) => {
        dispatch(SET_LOADER(false));
        setEventDetails(response.data)
        setTicketList(response.data.tickets)
      },
      () => dispatch(SET_LOADER(false))
    );
  }, []);
  return (
    <>
      <CreateTicket
        setTicketList={setTicketList}
        ticketModal={ticketModal}
        setTicketModal={setTicketModal}
        event_id={id || ""}
      />
      <Layout />
      {/* <!-- Body Start--> */}
      <div className="wrapper wrapper-body">
        <div className="event-dt-block">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12">
                <div className="main-title text-center">
                  <h3>Manage Event</h3>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9 col-md-12">
                <div className="wizard-steps-block">
                  <div id="add-event-tab" className="step-app">
                    {section === "event" && (
                      <EditEventSection
                      eventDetail={eventDetails}
                        onSubmit={() => {
                          setSection("ticket");
                        }}
                      />
                    )}
                    {section === "ticket" && (
                      <EditTicketSection
                        ticketList={ticketList}
                        setTicketModal={setTicketModal}
                        setTicketList={setTicketList}
                        goBack={() => setSection("event")}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Body End--> */}
    </>
  );
};

export default EditEvent;
