import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { SET_LOADER } from "../store/formSlice";
import { deleteEvent } from "../api/event";

const EventCard = ({ item, remove }: { item: any; remove: () => void }) => {
  const dispatch = useDispatch();
  const deleteOrgEvent = () => {
    dispatch(SET_LOADER(true));
    deleteEvent(
      item._id,
      (response) => {
        remove();
        dispatch(SET_LOADER(false));
      },
      () => dispatch(SET_LOADER(false))
    );
  };
  return (
    <>
      <div className="tab-pane fade show active">
        <div className="main-card mt-4">
          <div className="contact-list">
            <div className="card-top event-top p-4 align-items-center top d-md-flex flex-wrap justify-content-between">
              <div className="d-md-flex align-items-center event-top-info">
                <div className="card-event-img">
                  <img src={item?.image?.url} alt="" />
                </div>
                <div className="card-event-dt">
                  <h5>{item?.name}</h5>
                </div>
              </div>
              <div className="dropdown">
                <button
                  className="option-btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a href={`/event/${item?._id}`} className="dropdown-item">
                    <i className="fa-solid fa-gear me-3"></i>
                    Manage
                  </a>
                  <Link
                    to="#"
                    className="dropdown-item delete-event"
                    onClick={(e) => {
                      e.preventDefault();
                      deleteOrgEvent();
                    }}
                  >
                    <i className="fa-solid fa-trash-can me-3"></i>
                    Delete
                  </Link>
                </div>
              </div>
            </div>
            <div className="bottom d-flex flex-wrap justify-content-between align-items-center p-4">
              <div className="icon-box ">
                <span className="icon">
                  <i className="fa-solid fa-location-dot"></i>
                </span>
                <p>Location</p>
                <h6 className="coupon-status">
                  {item?.location?.line1}, {item?.location?.city},{" "}
                  {item?.location?.state}, {item?.location?.country}{" "}
                  {/* {item.location.postal_code} */}
                </h6>
              </div>
              <div className="icon-box">
                <span className="icon">
                  <i className="fa-solid fa-calendar-days"></i>
                </span>
                <p>Starts on</p>
                <h6 className="coupon-status">
                  {moment(item?.event_date).format("DD MMM, YYYY")}{" "}
                  {item?.event_time}
                </h6>
              </div>
              <div className="icon-box">
                <span className="icon">
                  <i className="fa-solid fa-ticket"></i>
                </span>
                <p>Ticket</p>
                <h6 className="coupon-status">
                  {item?.quantity?.toLocaleString()}
                </h6>
              </div>
              <div className="icon-box">
                <span className="icon">
                  <i className="fa-solid fa-tag"></i>
                </span>
                <p>Tickets sold</p>
                <h6 className="coupon-status">
                  {item?.sold_qty?.toLocaleString()}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventCard;
