import { toast } from "react-toastify";
import { API_URL as BASE_URL, MAX_API_RETRY } from "../utility/appConfig";
import axios from "axios";
import { refreshUserToken } from "./authentication";
import { store } from "../store";

let retryCount = 0;

export const createEvent = async (
  data: {
    name: string;
    category: string;
    event_date: string;
    event_time: string;
    duration: string;
    description: string;
    line1: string;
    city: string;
    state: string;
    country: string;
    postal_code: string;
    organization_id: string;
    image: File | null;
  },
  execute: (e: any) => void,
  error: () => void
) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("category", data.category);
  formData.append("description", data.description);
  formData.append("event_date", data.event_date);
  formData.append("event_time", data.event_time);
  formData.append("duration", data.duration);
  formData.append("line1", data.line1);
  formData.append("city", data.city);
  formData.append("state", data.state);
  formData.append("country", data.country);
  formData.append("postal_code", data.postal_code);
  formData.append("organization_id", data.organization_id);
  data.image && formData.append("image", data.image);
  var config = {
    method: "post",
    url: `${BASE_URL}/events`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
    data: formData,
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          createEvent({ ...data }, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
      error();
    }
  }
};


export const editEvent = async (
  id: string,
  data: {
    name: string;
    category: string;
    event_date: string;
    event_time: string;
    duration: string;
    description: string;
    line1: string;
    city: string;
    state: string;
    country: string;
    postal_code: string;
    image: File | null;
  },
  execute: (e: any) => void,
  error: () => void
) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("category", data.category);
  formData.append("description", data.description);
  formData.append("event_date", data.event_date);
  formData.append("event_time", data.event_time);
  formData.append("duration", data.duration);
  formData.append("line1", data.line1);
  formData.append("city", data.city);
  formData.append("state", data.state);
  formData.append("country", data.country);
  formData.append("postal_code", data.postal_code);
  data.image && formData.append("image", data.image);
  var config = {
    method: "put",
    url: `${BASE_URL}/events/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
    data: formData,
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          editEvent(id, { ...data }, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
      error();
    }
  }
};


export const createEventTicket = async (
  data: {
    name: string;
    price: number;
    description: string;
    total: number;
    event_id: string;
    is_active: boolean;
    is_unlimited: boolean;
    apply_discount: boolean;
    discount?: {
      percent: number;
      expiration_date: string;
    }
  },
  execute: (e: any) => void,
  error: () => void
) => {
  
  var config = {
    method: "post",
    url: `${BASE_URL}/events/tickets`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
    data,
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          createEventTicket({ ...data }, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
      error();
    }
  }
};


export const deleteEventTicket = async (
  id: string,
  execute: (e: any) => void,
  error: () => void
) => {
  
  var config = {
    method: "delete",
    url: `${BASE_URL}/events/tickets/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          deleteEventTicket(id, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
      error();
    }
  }
};


export const deleteEvent = async (
  id: string,
  execute: (e: any) => void,
  error: () => void
) => {
  
  var config = {
    method: "delete",
    url: `${BASE_URL}/events/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          deleteEvent(id, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
      error();
    }
  }
};


export const getOrgEvents = async (
  id: string,
  page: number,
  execute: (e: any) => void,
  error: () => void
) => {
  
  var config = {
    method: "get",
    url: `${BASE_URL}/events/organization/${id}?page=${page}`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          getOrgEvents(id, page, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
      error();
    }
  }
};


export const getEventDetail = async (
  id: string,
  execute: (e: any) => void,
  error: () => void
) => {
  
  var config = {
    method: "get",
    url: `${BASE_URL}/events/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          getEventDetail(id, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
      error();
    }
  }
};


export const getEventStats = async (
  id: string,
  execute: (e: any) => void,
  error: () => void
) => {
  
  var config = {
    method: "get",
    url: `${BASE_URL}/events/${id}/stats`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          getEventDetail(id, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
      error();
    }
  }
};
