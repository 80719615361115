import React from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { API_URL, MAX_API_RETRY } from "../utility/appConfig";
import { useDispatch } from "react-redux";
import { SET_LOADER } from "../store/formSlice";
import { refreshUserToken } from "../api/authentication";
import { SET_ORG_EVENTS, SET_ORG_PROFILE, SET_SELECTED_EVENT } from "../store/orgSlice";
import { SET_USER_DATA } from "../store/authSlice";

export function useFetchDetails() {
  const { accessToken, isLoggedIn, orgData } = useSelector(
    (state: RootState) => state.auth
  );
  const { selectedOrg } = useSelector(
    (state: RootState) => state.org
  );
  const dispatch = useDispatch();
  const retryCount = React.useRef<number>(0);

  const tokenRefresh = () => {
    if (isLoggedIn && accessToken) {
      dispatch(SET_LOADER(true));
      axios
        .all([
          axios({
            method: "get",
            url: `${API_URL}/organization/${
              selectedOrg && selectedOrg?.organization_id?._id
            }`,
            headers: { Authorization: `Bearer ${accessToken}` },
          }),
          axios({
            method: "get",
            url: `${API_URL}/users/profile`,
            headers: { Authorization: `Bearer ${accessToken}` },
          }),
          axios({
            method: "get",
            url: `${API_URL}/events/organization/${
              selectedOrg && selectedOrg?.organization_id?._id
            }?paginate=false`,
            headers: { Authorization: `Bearer ${accessToken}` },
          }),
        ])
        .then(
          axios.spread((orgProfile, userProfile, organization_event) => {
            console.log(organization_event.data.data?.docs);
            dispatch(SET_LOADER(false));
            dispatch(SET_ORG_PROFILE(orgProfile.data.data));
            dispatch(SET_USER_DATA(userProfile.data.data));
            dispatch(SET_ORG_EVENTS(organization_event.data.data?.docs));
            dispatch(SET_SELECTED_EVENT(organization_event.data.data?.docs[0]));
          })
        )
        .catch((err) => {
          if (
            err?.response?.status === 401 &&
            retryCount.current < MAX_API_RETRY
          ) {
            retryCount.current++;
            refreshUserToken(
              retryCount.current,
              (response) => {
                tokenRefresh();
              },
              () => dispatch(SET_LOADER(false))
            );
          } else {
            dispatch(SET_LOADER(false));
            toast.error(
              err?.response?.data?.message || "Something went wrong. Try again."
            );
          }
        });
    } else {
      dispatch(SET_ORG_PROFILE(null));
      dispatch(SET_USER_DATA(null));
      dispatch(SET_ORG_EVENTS([]));
      dispatch(SET_SELECTED_EVENT(null));
    }
  };

  React.useEffect(() => {
    tokenRefresh();
    // eslint-disable-next-line
  }, [isLoggedIn, accessToken, orgData]);

  return null;
}
