import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/layout";
import { useOptimizedEffect } from "../../hooks/useOptimizedEffect";
import { useDispatch } from "react-redux";
import { SET_LOADER } from "../../store/formSlice";
import { getOrgEvents } from "../../api/event";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/bootstrap.css";
import EventCard from "../../components/eventCard";

const Event = () => {
  const [events, setEvents] = React.useState<any>(null);
  const [page, setPage] = React.useState<number>(1);
  const { selectedOrg } = useSelector((state: RootState) => state.org);
  const dispatch = useDispatch();

  useOptimizedEffect(() => {
    dispatch(SET_LOADER(true));
    getOrgEvents(
      selectedOrg?.organization_id?._id || "",
      page,
      (response) => {
        dispatch(SET_LOADER(false));
        setEvents(response.data);
        console.log(response.data);
      },
      () => dispatch(SET_LOADER(false))
    );
  });
  return (
    <>
      <Layout />
      {/* <!-- Body Start --> */}
      <div className="wrapper wrapper-body">
        <div className="dashboard-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="d-main-title">
                  <h3>
                    <i className="fa-solid fa-calendar-days me-3"></i>Events
                  </h3>
                </div>
              </div>
              <div className="col-md-12">
                <div className="main-card mt-5">
                  <div className="dashboard-wrap-content p-4">
                    <h5 className="mb-4">Events (1)</h5>
                    <div className="d-md-flex flex-wrap align-items-center">
                      <div className="dashboard-date-wrap">
                        <div className="form-group">
                          <div className="relative-input position-relative">
                            <input
                              className="form-control h_40"
                              type="text"
                              placeholder="Search by event name"
                              value=""
                            />
                            <i className="uil uil-search"></i>
                          </div>
                        </div>
                      </div>
                      <div className="rs ms-auto mt_r4">
                        <div
                          className="nav custom2-tabs btn-group"
                          role="tablist"
                        >
                          <button
                            className="tab-link active"
                            data-bs-toggle="tab"
                            data-bs-target="#all-tab"
                            type="button"
                            role="tab"
                            aria-controls="all-tab"
                            aria-selected="true"
                          >
                            All (<span className="total_event_counter">1</span>)
                          </button>
                          <button
                            className="tab-link"
                            data-bs-toggle="tab"
                            data-bs-target="#online-tab"
                            type="button"
                            role="tab"
                            aria-controls="online-tab"
                            aria-selected="false"
                          >
                            Upcoming (
                            <span className="total_event_counter">0</span>)
                          </button>
                          <button
                            className="tab-link"
                            data-bs-toggle="tab"
                            data-bs-target="#venue-tab"
                            type="button"
                            role="tab"
                            aria-controls="venue-tab"
                            aria-selected="false"
                          >
                            Past (<span className="total_event_counter">1</span>
                            )
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="event-list">
                  <div className="tab-content">
                    {events?.docs.map((item: any, key: number) => (
                      <EventCard
                        remove={() =>
                          setEvents((prev: any) => ({
                            ...prev,
                            docs: prev.docs.filter(
                              (data: any) => data._id !== item._id
                            ),
                          }))
                        }
                        item={item}
                        key={key.toString()}
                      />
                    ))}
                  </div>
                  <ResponsivePagination
                    current={page}
                    total={events?.totalPage || 1}
                    onPageChange={(current) => setPage(current)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Event;
