import React from "react";
import { useDispatch } from "react-redux";
import { SET_LOADER } from "../store/formSlice";
import { resendOtp, sendOtp } from "../api/authentication";
import { toast } from "react-toastify";

let timer: any = () => {};

const CountDowner = (forwardEmail: string, shouldSend: boolean) => {
  const [timeLeft, setTimeLeft] = React.useState(60);
  const ref = React.useRef<number>(0);
  const dispatch = useDispatch();

  const resendUserOtp = () => {
    dispatch(SET_LOADER(true));
    resendOtp({email: forwardEmail}, (response) => {
      toast.success(response.message);
      dispatch(SET_LOADER(false));
    }, () => dispatch(SET_LOADER(false)))
  }

  const startTimer = () => {
    timer = setTimeout(() => {
      if (timeLeft <= 0) {
        clearTimeout(timer);
        return false;
      }
      setTimeLeft(timeLeft - 1);
    }, 1300);
  };

  React.useEffect(() => {
    startTimer();
    return () => clearTimeout(timer);
  });

  React.useEffect(() => {
    if (ref.current === 0 && shouldSend) {
      dispatch(SET_LOADER(true));
      sendOtp(
        { email: forwardEmail },
        false,
        (response) => {
          toast.success(response.message);
          dispatch(SET_LOADER(false));
        },
        () => {
          dispatch(SET_LOADER(false));
        }
      );
      ref.current = 1;
    }
    // eslint-disable-next-line
  }, [forwardEmail, shouldSend]);

  const start = () => {
    setTimeLeft(60);
    clearTimeout(timer);
    startTimer();
    resendUserOtp();
  };

  return { timeLeft, start };
};

export default CountDowner;
