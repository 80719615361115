import React from "react";
// import { Link } from "react-router-dom";
import Layout from "../../components/layout";
import EventSection from "../../components/eventSection";
import { TicketSection, CreateTicket } from "../../components/ticketSection";

const CreateEvent = () => {
  const [section, setSection] = React.useState<"event" | "ticket">("event");
  const event_id = React.useRef<string>("");
  const [ticketList, setTicketList] = React.useState<any[]>([]);
  const [ticketModal, setTicketModal] = React.useState<boolean>(false)
  return (
    <>
      <CreateTicket setTicketList={setTicketList} ticketModal={ticketModal} setTicketModal={setTicketModal} event_id={event_id.current} />
      <Layout />
      {/* <!-- Body Start--> */}
      <div className="wrapper wrapper-body">
        <div className="event-dt-block">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12">
                <div className="main-title text-center">
                  <h3>Create New Event</h3>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9 col-md-12">
                <div className="wizard-steps-block">
                  <div id="add-event-tab" className="step-app">
                    {section === "event" && (
                      <EventSection
                        onSubmit={(id) => {
                          event_id.current = id;
                          setSection("ticket");
                        }}
                      />
                    )}
                    {section === "ticket" && (
                      <TicketSection ticketList={ticketList} setTicketModal={setTicketModal} setTicketList={setTicketList} goBack={() => setSection("event")} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Body End--> */}
    </>
  );
};

export default CreateEvent;
