import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/layout";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { AiOutlineCheck, AiOutlinePlus } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { SET_LOADER } from "../../store/formSlice";
import { editOrg } from "../../api/organisation";
import { toast } from "react-toastify";
import { response } from "express";
import { FirstLetterUppercase } from "../../utility/helper";
import { SET_ORG_PROFILE } from "../../store/orgSlice";

const EditProfile = () => {
  const dispatch = useDispatch();
  const { orgProfile, selectedOrg } = useSelector(
    (state: RootState) => state.org
  );
  const [image, setImage] = React.useState<File | null>(null);
  const [name, setName] = React.useState<string>(orgProfile?.name || "");
  const [description, setDescription] = React.useState(
    orgProfile?.description || ""
  );

  const validate = () => {
    dispatch(SET_LOADER(true));
    editOrg(
      selectedOrg?.organization_id?._id || "",
      { image, name, description },
      (response) => {
        orgProfile && dispatch(SET_ORG_PROFILE({...orgProfile, name, description}))
        toast.success(response.message);
        dispatch(SET_LOADER(false));
      },
      () => dispatch(SET_LOADER(false))
    );
  };
  return (
    <>
      {/* <!-- Organisation Profile Update Model Start--> */}
      <div
        className="modal fade"
        id="org-profile-update-pop"
        tabIndex={-1}
        aria-labelledby="orgProfileUpdatepopLabel"
        aria-hidden="false"
      >
        <div className="modal-dialog modal-medium-2 modal-dialog-scrollable modal-dialog-centered modal-sm-height modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="orgProfileUpdatepopLabel">
                Organisation details
              </h5>
              <button
                type="button"
                className="close-model-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="uil uil-multiply"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="model-content main-form">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="w-full mb-8 ">
                      <div className="max-w-[400px] rounded-md mx-auto h-[150px] bg-[#F9F9F9BF] mt-2 flex justify-center items-center relative">
                        <div className="flex flex-col items-center">
                          <div className="w-[50px] h-[50px] flex justify-center items-center bg-white rounded-full shadow-md">
                            {image ? (
                              <AiOutlineCheck color="#004F5D" size={25} />
                            ) : (
                              <AiOutlinePlus color="#004F5D" size={25} />
                            )}
                          </div>
                          <p className="font-Nunito text-darkGrey text-[14px] mt-[10px]">
                            {image
                              ? "Logo uploaded Successfully"
                              : "Upload Organization Logo"}
                          </p>
                        </div>
                        <input
                          type="file"
                          onChange={(e) => {
                            setImage(e.target.files && e.target.files[0]);
                            // dispatch(SET_LOADER(true));
                            // uploadImage(
                            //   orgProfile?.image?._id || "",
                            //   {
                            //     image: e.target.files && e.target.files[0],
                            //   },
                            //   (response) => {
                            //     setImage(e.target.files && e.target.files[0]);
                            //     dispatch(SET_LOADER(false));
                            //   },
                            //   () => dispatch(SET_LOADER(false))
                            // );
                          }}
                          className="absolute top-0 left-0 w-full h-full opacity-0"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">Organization Name*</label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">Description*</label>
                      <textarea
                        className="form-textarea"
                        placeholder="About"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="co-main-btn min-width btn-hover h_40 transition-all"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                // data-bs-toggle="modal"
                // data-bs-dismiss="modal"
                onClick={validate}
                className="main-btn min-width btn-hover h_40 transition-all text-[#004F5D] hover:text-white"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Organisation Profile Update Model End--> */}
    </>
  );
};

const Profile = () => {
  const { orgProfile, selectedOrg } = useSelector(
    (state: RootState) => state.org
  );
  return (
    <div className="d-flex flex-column h-100">
      <EditProfile />
      <Layout />
      {/* <!-- Body Start --> */}
      <div className="wrapper wrapper-body">
        <div className="dashboard-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="d-main-title">
                  <h3>
                    <i className="fa-solid fa-circle-info me-3"></i>About My
                    Organisation
                  </h3>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="conversion-setup">
                  <div className="main-card mt-5">
                    <div className="bp-title position-relative">
                      <h4>About</h4>
                      <div className="profile-edit-btn">
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#org-profile-update-pop"
                          className="btn"
                        >
                          <i className="fa-solid fa-pen"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="about-details">
                      <div className="about-step text-center">
                        <div className="user-avatar-img flex justify-center">
                          <img src={orgProfile?.image?.url} alt="" />
                        </div>
                        <div className="user-dts">
                          <h4 className="user-name">
                            {FirstLetterUppercase(orgProfile?.name || "")}
                            <span className="verify-badge">
                              <i className="fa-solid fa-circle-check"></i>
                            </span>
                          </h4>
                          <span className="user-email">
                            {FirstLetterUppercase(selectedOrg?.role || "")}
                          </span>
                        </div>
                      </div>
                      <div className="about-step">
                        <h5>
                          Tell us about organization and let people know what you do
                        </h5>
                        <p className="mb-0">
                          {orgProfile?.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Body End --> */}
    </div>
  );
};

export default Profile;
