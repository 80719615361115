import React from "react";
// import { Link } from "react-router-dom";
import Layout from "../../components/layout";
import { FirstLetterUppercase, validateEmail } from "../../utility/helper";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  addUserToOrg,
  deleteUsersInOrg,
  getUsersInOrg,
} from "../../api/organisation";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { SET_LOADER } from "../../store/formSlice";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/bootstrap.css";
import { useOptimizedEffect } from "../../hooks/useOptimizedEffect";
import moment from "moment";

const Invite = ({ increment }: { increment: () => void }) => {
  const { selectedOrg } = useSelector((state: RootState) => state.org);
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState("");
  const [role, setRole] = React.useState("");

  const validate = () => {
    if (!validateEmail(email)) {
      toast.error("Email Address is invalid.");
    } else if (role === "") {
      toast.error("Select a role for the user.");
    } else {
      dispatch(SET_LOADER(true));
      addUserToOrg(
        selectedOrg?.organization_id?._id || "",
        { email, role },
        (response) => {
          increment();
          dispatch(SET_LOADER(false));
        },
        () => dispatch(SET_LOADER(false))
      );
    }
  };
  return (
    <>
      {/* <!-- Invite Team Member Model Start--> */}
      <div
        className="modal fade"
        id="inviteTeamModal"
        tabIndex={-1}
        aria-labelledby="inviteTeamModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="inviteTeamModalLabel">
                Invite a Team Member
              </h5>
              <button
                type="button"
                className="close-model-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="uil uil-multiply"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="model-content main-form">
                <div className="form-group mt-30">
                  <label className="form-label">
                    Which team members do you wish to invite?*
                  </label>
                  <input
                    className="form-control h_40 focus:!border-primary"
                    type="text"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group mt-30">
                  <label className="form-label">
                    What role do you wish to assign?*
                  </label>
                  <select
                    className="form-control h_40 focus:!border-primary"
                    title="Select Role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option value="">Select a role</option>
                    <option value="owner">Owner</option>
                    <option value="admin">Admin</option>
                    <option value="staff">Staff</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="co-main-btn min-width btn-hover h_40"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={validate}
                data-bs-dismiss="modal"
                className="main-btn min-width btn-hover h_40 transition-all text-[#004F5D] hover:text-white"
              >
                Invite
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Invite Team Member Model End--> */}
    </>
  );
};

const Team = () => {
  const [increment, setIncrement] = React.useState(0);
  const [team, setTeam] = React.useState<any>(null);
  const [page, setPage] = React.useState<number>(1);
  const { selectedOrg } = useSelector((state: RootState) => state.org);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    getUsersInOrg(
      selectedOrg?.organization_id?._id || "",
      page,
      (response) => {
        dispatch(SET_LOADER(false));
        setTeam(response.data);
        console.log(response.data);
      },
      () => dispatch(SET_LOADER(false))
    );
  }, [increment]);

  return (
    <>
      <Invite increment={() => setIncrement(increment + 1)} />
      <Layout />
      {/* <!-- Body Start --> */}
      <div className="wrapper wrapper-body">
        <div className="dashboard-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="d-main-title">
                  <h3>
                    <i className="fa-solid fa-user-group me-3"></i>Team Members
                  </h3>
                </div>
              </div>
              <div className="col-md-12">
                <div className="conversion-setup">
                  <div className="main-card mt-5">
                    <div className="dashboard-wrap-content p-4">
                      <div className="d-md-flex flex-wrap align-items-center">
                        <div
                          className="nav custom2-tabs btn-group"
                          role="tablist"
                        >
                          {/* <button
                            className="tab-link ms-0"
                          >
                            Overview
                          </button>
                          <button
                            className="tab-link"
                          >
                            Role
                          </button> */}
                        </div>
                        <div className="rs ms-auto mt_r4">
                          <button
                            className="main-btn btn-hover h_40 w-100"
                            data-bs-toggle="modal"
                            data-bs-target="#inviteTeamModal"
                          >
                            Invite a Team Member
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="overview-tab"
                      role="tabpanel"
                    >
                      <div className="table-card mt-4">
                        <div className="main-table">
                          <div className="table-responsive">
                            <table className="table">
                              <thead className="thead-dark">
                                <tr>
                                  <th scope="col">Name</th>
                                  <th scope="col">Email</th>
                                  <th scope="col">Role</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {team?.docs.map((item: any, key: number) => (
                                  <tr key={key.toString()}>
                                    <td>
                                      {FirstLetterUppercase(
                                        item.user_id.first_name || ""
                                      )}{" "}
                                      {FirstLetterUppercase(
                                        item.user_id.last_name || ""
                                      )}
                                    </td>
                                    <td>
                                      {FirstLetterUppercase(
                                        item.user_id.email || ""
                                      )}
                                    </td>
                                    <td>
                                      {FirstLetterUppercase(item.role || "")}
                                    </td>
                                    <td>
                                      {moment(item.created_at).format(
                                        "DD MMM YYYY, HH:mm A"
                                      )}
                                    </td>
                                    <td>
                                      {FirstLetterUppercase(item.status || "s")}
                                    </td>
                                    <td>
                                      <span className="action-btn">
                                        {item.role === "owner" ? (
                                          <i className="fa-solid fa-lock"></i>
                                        ) : (
                                          <i
                                            className="fa-solid fa-trash-can"
                                            onClick={() => {
                                              dispatch(SET_LOADER(true));
                                              deleteUsersInOrg(
                                                selectedOrg?.organization_id
                                                  ?._id || "",
                                                { invitation_id: item._id },
                                                (response) => {
                                                  toast.success(
                                                    response.message
                                                  );
                                                  setTeam((prev: any) => ({
                                                    ...prev,
                                                    docs: prev.docs.filter(
                                                      (data: any) =>
                                                        data._id !== item._id
                                                    ),
                                                  }));
                                                  dispatch(SET_LOADER(false));
                                                },
                                                () =>
                                                  dispatch(SET_LOADER(false))
                                              );
                                            }}
                                          ></i>
                                        )}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <ResponsivePagination
                              current={page}
                              total={team?.totalPage || 1}
                              onPageChange={(current) => setPage(current)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Body End -->	 */}
    </>
  );
};

export default Team;
