import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IOrg {
  _id?: string;
  user_id?: string;
  status?: string;
  role?: string;
  created_at?: string;
  updated_at?: string;
  __v?: number;
  organization_id?: {
    name?: string;
    _id?: string;
  };
}

export interface IUser {
  _id: string;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  disabled: boolean;
  dial_code: string;
  phone_number: string;
  is_verified: boolean;
  enable_SQ: boolean;
  created_at: string;
  updated_at: string;
  __v: number;
}

export interface IAuth {
  refreshToken: string | null;
  accessToken: string | null;
  isLoggedIn: boolean;

  orgData: IOrg[] | null;
  userData: IUser | null
}

const initialState: IAuth = {
  refreshToken: null,
  accessToken: null,
  isLoggedIn: false,
  orgData: null,
  userData: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    SET_REFRESH_TOKEN: (state, action: PayloadAction<string | null>) => {
      state.refreshToken = action.payload;
    },
    SET_ACCESS_TOKEN: (state, action: PayloadAction<string | null>) => {
      state.accessToken = action.payload;
    },
    SET_LOGGEDIN: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    SET_ORG_DATA: (state, action: PayloadAction<IOrg[] | null>) => {
      state.orgData = action.payload;
    },
    SET_USER_DATA: (state, action: PayloadAction<IUser | null>) => {
      state.userData = action.payload;
    },
  },
});

export const {
  SET_ACCESS_TOKEN,
  SET_REFRESH_TOKEN,
  SET_LOGGEDIN,
  SET_ORG_DATA,
  SET_USER_DATA,
} = authSlice.actions;

export default authSlice.reducer;
