import React from "react";
import { Link } from "react-router-dom";
import { FirstLetterUppercase } from "../utility/helper";
import { useDispatch } from "react-redux";
import { SET_LOADER } from "../store/formSlice";
import { deleteEventTicket } from "../api/event";
import moment from "moment";

const TicketCard = ({
  item,
  setTicketList,
}: {
  item: any;
  setTicketList: React.Dispatch<React.SetStateAction<any[]>>;
}) => {
  const dispatch = useDispatch();
  const deleteTicket = () => {
    dispatch(SET_LOADER(true));
    deleteEventTicket(
      item._id,
      (response) => {
        dispatch(SET_LOADER(false));
        setTicketList((prev) => prev.filter((data) => item._id !== data._id));
      },
      () => dispatch(SET_LOADER(false))
    );
  };
  return (
    <>
      <div className="price-ticket-card mt-4">
        <div className="price-ticket-card-head d-md-flex flex-wrap align-items-start justify-content-between position-relative p-4">
          <div className="d-flex align-items-center top-name">
            <div className="icon-box">
              <span className="icon-big rotate-icon icon icon-purple">
                <i className="fa-solid fa-ticket"></i>
              </span>
              <h5 className="fs-16 mb-1 mt-1">
                {FirstLetterUppercase(item.name)} - ₦
                {item.price.toLocaleString()}
              </h5>
              <p className="text-gray-50 m-0">
                <span className="visitor-date-time">
                  {moment(item.created_at).format("MMMM DD, YYYY")}
                </span>
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center">
            {item.discount.percent > 0 && (
              <div className="price-badge">
                <img src="/images/discount.png" alt="" />
              </div>
            )}

            <label className="btn-switch tfs-8 mb-0 me-4 mt-1">
              <input type="checkbox" value="" checked />
              <span className="checkbox-slider"></span>
            </label>
            <div className="dropdown dropdown-default dropdown-text dropdown-icon-item">
              <button
                className="option-btn-1"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa-solid fa-ellipsis-vertical"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                {/* <Link to="#" className="dropdown-item">
                  <i className="fa-solid fa-pen me-3"></i>
                  Edit
                </Link> */}
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={(e) => {
                    e.preventDefault();
                    deleteTicket();
                  }}
                >
                  <i className="fa-solid fa-trash-can me-3"></i>
                  Delete
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="price-ticket-card-body border_top p-4">
          <div className="full-width d-flex flex-wrap justify-content-between align-items-center">
            <div className="icon-box">
              <div className="icon me-3">
                <i className="fa-solid fa-ticket"></i>
              </div>
              <span className="text-145">Total tickets</span>
              <h6 className="coupon-status">{item.total}</h6>
            </div>
            <div className="icon-box">
              <div className="icon me-3">
                <i className="fa-solid fa-ticket"></i>
              </div>
              <span className="text-145">Ticket sold out</span>
              <h6 className="coupon-status">{item.sold_qty}</h6>
            </div>
            <div className="icon-box">
              <div className="icon me-3">
                <i className="fa-solid fa-cart-shopping"></i>
              </div>
              <span className="text-145">Discount</span>
              <h6 className="coupon-status">{item.discount.percent}%</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketCard;
