import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CountDowner from "../../hooks/countDowner";
import { toast } from "react-toastify";
import { verifyEmail } from "../../api/authentication";
import { SET_LOADER } from "../../store/formSlice";

const Otp = () => {
  const location = useLocation();
  const { start, timeLeft } = CountDowner(
    location.state.email,
    location.state.send
  );
  const [otp, setOtp] = React.useState<string>("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validate = () => {
    if (otp.length !== 4) {
      toast.error("OTP password must be 4 digits.");
    } else {
      dispatch(SET_LOADER(true));
      verifyEmail(
        { email: location.state.email, password: otp },
        "otp",
        (response) => {
          toast.success(response.message);
          dispatch(SET_LOADER(false));
          if (location.state.type === "email") {
            if (location.state.goToCreate) {
              navigate("/create-org");
            } else {
              navigate("/signin");
            }
          } else {
            navigate("/create-password");
          }
        },
        () => {
          dispatch(SET_LOADER(false));
        }
      );
    }
  };

  return (
    <>
      <div className="form-wrapper">
        <div className="app-form">
          <div className="app-form-sidebar">
            <div className="sidebar-sign-logo">
              <img src="/images/sign-logo.svg" alt="" />
            </div>
            <div className="sign_sidebar_text">
              <h1>
                The Easiest Way to Create Events and Sell More Tickets Online
              </h1>
            </div>
          </div>
          <div className="app-form-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-10">
                  <div className="app-top-items">
                    <Link to="/">
                      <div className="sign-logo" id="logo">
                        <img src="/images/logo.svg" alt="" />
                        <img
                          className="logo-inverse"
                          src="/images/dark-logo.svg"
                          alt=""
                        />
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-7">
                  <div className="registration">
                    <form>
                      <h2 className="registration-title">OTP Verification</h2>
                      <div className="form-group mt-5">
                        <label className="form-label">
                          Your One-TIme Password*
                        </label>
                        <input
                          className="form-control h_50"
                          type="number"
                          placeholder="Enter your OTP password"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </div>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          validate();
                        }}
                        className="main-btn btn-hover w-100 mt-4 text-[#ffffff] transition-all hover:text-white"
                      >
                        Verify
                      </button>
                      <div className="w-full flex justify-center">
                        <p className="mx-auto mt-2">
                          Didn’t receive the code?{" "}
                          <span
                            className="text-primary font-semibold cursor-pointer hover:underline"
                            onClick={() => {
                              if (timeLeft <= 0) {
                                start();
                              }
                            }}
                          >
                            {timeLeft <= 0
                              ? "Resend"
                              : `00:${timeLeft.toString().padStart(2, "0")}`}
                          </span>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright-footer">
              © 2024, Usepuplar. All rights reserved. Powered by Akawo Synergy
              Limited
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
