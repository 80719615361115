import { store } from "../store";
import { toast } from "react-toastify";
import {
  SET_ACCESS_TOKEN,
  SET_LOGGEDIN,
  SET_ORG_DATA,
  SET_REFRESH_TOKEN,
} from "../store/authSlice";
import { API_URL as BASE_URL, MAX_API_RETRY } from "../utility/appConfig";
import axios from "axios";
import { SET_SELECTED_ORG } from "../store/orgSlice";

let retryCount = 0;

export const refreshUserToken = async (
  retryCounts: number,
  execute: (e: any) => void,
  error: () => void
) => {
  var config = {
    method: "get",
    url: `${BASE_URL}/auth/refresh-token`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.refreshToken}`,
    },
  };

  try {
    const response = await axios(config);
    retryCounts = 0;
    store.dispatch(SET_ACCESS_TOKEN(response.data.data.access_token));
    store.dispatch(SET_REFRESH_TOKEN(response.data.data.refresh_token));
    execute(response.data);
  } catch (err: any) {
    store.dispatch(SET_LOGGEDIN(false));
    store.dispatch(SET_ACCESS_TOKEN(null));
    store.dispatch(SET_REFRESH_TOKEN(null));
    store.dispatch(SET_ORG_DATA(null));
    error();
    toast.error(
      err?.response?.data?.message || "Something went wrong. Try again."
    );
  }
};

export const sendOtp = async (
  data: {
    email: string;
  },
  is_new: boolean, //   if the user does not exist be true else false
  execute: (e: any) => void,
  error: () => void
) => {
  var config = {
    method: "post",
    url: `${BASE_URL}/auth/send/otp?is_new=${is_new}`,
    data: data,
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          sendOtp({ ...data }, is_new, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
    }
  }
};

export const resendOtp = async (
  data: {
    email: string;
  }, //   if the user does not exist be true else false
  execute: (e: any) => void,
  error: () => void
) => {
  var config = {
    method: "post",
    url: `${BASE_URL}/auth/resend/otp`,
    data: data,
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          resendOtp({ ...data }, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
    }
  }
};

export const verifyEmail = async (
  data: {
    email: string;
    password: string;
  },
  type: string, //   "email" | "otp",
  execute: (e: any) => void,
  error: () => void
) => {
  var config = {
    method: "post",
    url: `${BASE_URL}/auth/verify/${type}`,
    data: data,
  };

  try {
    const response = await axios(config);
    store.dispatch(SET_ACCESS_TOKEN(response.data.data.access_token));
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          verifyEmail({ ...data }, type, execute, error);
        },
        error
      );
    } else {
      error();
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
    }
  }
};

export const createPassword = async (
  data: {
    password: string;
    confirm_password: string;
  },
  execute: (e: any) => void,
  error: () => void
) => {
  var config = {
    method: "post",
    url: `${BASE_URL}/auth/create-password`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
    data: data,
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          createPassword({ ...data }, execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
    }
  }
};

export const getUserProfile = async (
  execute: (e: any) => void,
  error: () => void
) => {
  var config = {
    method: "post",
    url: `${BASE_URL}/users/profile`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    if (err?.response?.status === 401 && retryCount < MAX_API_RETRY) {
      retryCount++;
      refreshUserToken(
        retryCount,
        (response) => {
          getUserProfile(execute, error);
        },
        error
      );
    } else {
      toast.error(
        err?.response?.data?.message || "Something went wrong. Try again."
      );
    }
  }
};

export const login = async (
  data: {
    email: string;
    password: string;
  },
  execute: (e: any) => void,
  error: () => void
) => {
  var config = {
    method: "post",
    url: `${BASE_URL}/auth/login/organization`,
    data: data,
  };

  try {
    const response = await axios(config);
    store.dispatch(SET_ACCESS_TOKEN(response.data.data?.access_token));
    store.dispatch(SET_REFRESH_TOKEN(response.data.data?.refresh_token));
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    error();
    toast.error(
      err?.response?.data?.message || "Something went wrong. Try again."
    );
  }
};

export const register = async (
  data: {
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    dial_code: string;
    invitation_id: string | null;
  },
  execute: (e: any) => void,
  error: () => void
) => {
  console.log(data);
  var config = {
    method: "post",
    url: `${BASE_URL}/auth/register`,
    data,
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    console.log(err?.response?.data);
    error();
    toast.error(
      err?.response?.data?.message || "Something went wrong. Try again."
    );
  }
};

export const logout = async (execute: (e: any) => void, error: () => void) => {
  var config = {
    method: "delete",
    url: `${BASE_URL}/auth/logout`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    },
  };

  try {
    const response = await axios(config);
    execute(response.data);
    store.dispatch(SET_ACCESS_TOKEN(null));
    store.dispatch(SET_REFRESH_TOKEN(null));
    store.dispatch(SET_ORG_DATA(null));
    store.dispatch(SET_SELECTED_ORG(null));
    store.dispatch(SET_LOGGEDIN(false));
  } catch (err: any) {
    console.log(err?.response?.data);
    error();
    toast.error(
      err?.response?.data?.message || "Something went wrong. Try again."
    );
  }
};
