import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { validateEmail } from "../../utility/helper";
import { toast } from "react-toastify";
import { login } from "../../api/authentication";
import { useDispatch } from "react-redux";
import { SET_LOADER } from "../../store/formSlice";
import { SET_LOGGEDIN, SET_ORG_DATA } from "../../store/authSlice";
import { SET_SELECTED_ORG } from "../../store/orgSlice";

const Signin = () => {
  const navigate = useNavigate();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [visible, setVisible] = React.useState(false);
  const dispatch = useDispatch();

  const validate = () => {
    if (!validateEmail(email)) {
      toast.error("Email is invalid");
    } else if (password.length < 6) {
      toast.error("Password must be 6 characters long or more.");
    } else {
      dispatch(SET_LOADER(true));
      login(
        { email, password },
        (response) => {
          console.log(response);
          dispatch(SET_LOADER(false));
          toast.success(response.message);
          if (response.data.is_verified) {
            if (response.data.organization.length > 0) {
              dispatch(SET_ORG_DATA(response.data.organization));
              dispatch(SET_SELECTED_ORG(response.data.organization[0]))
              dispatch(SET_LOGGEDIN(true));
              window.location.href = "/";
            } else {
              navigate("/create-org");
            }
          } else {
            navigate("/verify-otp", {
              state: { type: "email", email, send: false, goToCreate: false }, // refers to the send to send an email upon getting to the verify otp page
            });
          }
        },
        () => dispatch(SET_LOADER(false))
      );
    }
  };
  return (
    <>
      <div className="form-wrapper">
        <div className="app-form">
          <div className="app-form-sidebar">
            <div className="sidebar-sign-logo">
              <img src="/images/sign-logo.svg" alt="" />
            </div>
            <div className="sign_sidebar_text">
              <h1>
                The Easiest Way to Create Events and Sell More Tickets Online
              </h1>
            </div>
          </div>
          <div className="app-form-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-10">
                  <div className="app-top-items">
                    <Link to="/">
                      <div className="sign-logo" id="logo">
                        <img src="/images/logo.svg" alt="" />
                        <img
                          className="logo-inverse"
                          src="/images/dark-logo.svg"
                          alt=""
                        />
                      </div>
                    </Link>
                    <div className="app-top-right-link">
                      New to Puplar?
                      <Link className="sidebar-register-link" to="/signup">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-7">
                  <div className="registration">
                    <form>
                      <h2 className="registration-title">Sign in to Puplar</h2>
                      <div className="form-group mt-5">
                        <label className="form-label">Your Email*</label>
                        <input
                          className="form-control h_50 focus:!border-primary"
                          type="email"
                          placeholder="Enter your email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group mt-4">
                        <div className="field-password">
                          <label className="form-label">Password*</label>
                          <Link
                            className="forgot-pass-link"
                            to="/forgot-password"
                          >
                            Forgot Password?
                          </Link>
                        </div>
                        <div className="loc-group position-relative">
                          <input
                            className="form-control h_50 focus:!border-primary"
                            type={visible ? "text" : "password"}
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <span className="pass-show-eye">
                            {visible ? (
                              <i
                                onClick={() => setVisible(!visible)}
                                className="fas fa-eye-slash"
                              ></i>
                            ) : (
                              <i
                                onClick={() => setVisible(!visible)}
                                className="fas fa-eye"
                              ></i>
                            )}
                          </span>
                        </div>
                      </div>
                      <button
                        className="main-btn btn-hover w-100 mt-4 text-[#004F5D] transition-all hover:text-white"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          validate();
                        }}
                      >
                        Sign In <i className="fas fa-sign-in-alt ms-2"></i>
                      </button>
                    </form>
                    <div className="new-sign-link mt-4">
                      New to Puplar?
                      <Link className="signup-link" to="/signup">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright-footer">
              © 2024, Usepuplar. All rights reserved. Powered by Akawo Synergy
              Limited
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;
