import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../store";

export const PublicRoute = ({
  children,
  redirectTo,
}: {
  children: JSX.Element;
  redirectTo: string;
}) => {
  const navigate = useNavigate();
  const { isLoggedIn, accessToken } = useSelector(
    (state: RootState) => state.auth
  );
  const [main, setMain] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (isLoggedIn && accessToken) {
      window.location.href = `${redirectTo}`;
      setMain(false);
    } else {
      setMain(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, accessToken]);

  return !main ? null : children;
};
