import moment from "moment";
import { toast } from "react-toastify";

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePhone = (phone: string, length = 11) => {
  return phone.match(/\d/g)!.length === length;
};

export const validatePassword = (password: string) => {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
  return re.test(password);
};

export const DelayFor = (number = 2000, fire: () => void) => {
  setTimeout(() => fire(), number);
};

export const FirstLetterUppercase = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const FirstLetterUppercaseWord = (string: string) => {
  return string.split(" ").map((item) => FirstLetterUppercase(item)).join(" ")
};

export const TruncateString = (string: string, index = 20) => {
  return string.length > index ? string.substring(0, index) + "..." : string;
};

export const validateConfirmPassword = (
  password: string,
  confirmPassword: string
) => {
  if (confirmPassword !== password) {
    return false;
  } else {
    return true;
  }
};

export const VALIDATE_USER_DATA = (
  data: any,
  execute: (arg0: any) => void
  //   dispatch: Dispatch<AnyAction>
) => {
  for (const [key, value] of Object.entries(data)) {
    if (value === "") {
      let errorMessage = "";
      switch (key) {
        case "first_name":
          errorMessage = "First Name";
          break;
        case "last_name":
          errorMessage = "Last Name";
          break;
        case "phone_number":
          errorMessage = "Phone Number";
          break;
        case "event_date":
          errorMessage = "Event Date";
          break;
        case "event_time":
          errorMessage = "Event Time";
          break;
        case "postal_code":
          errorMessage = "Postal Code";
          break;
        case "line1":
          errorMessage = "Venue";
          break;
        default:
          errorMessage = key.charAt(0).toUpperCase() + key.slice(1);
          break;
      }
      //   dispatch(SET_ERROR(`${errorMessage} is invalid`));
      toast.error(`${errorMessage} is invalid`);
      return false;
    }
  }

  execute(data);
};

export function convertSecondsToHoursAndMinutes(seconds: number): string {
  const duration = moment.duration(seconds, "minutes");
  const hours = duration.hours();
  const minutes = duration.minutes();
  return `${hours > 0 ? hours + "h" : ""}${
    minutes > 0 && hours > 0 ? " " : ""
  }${minutes > 0 ? minutes + "m" : ""}`;
}
