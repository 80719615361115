import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { validateEmail } from "../../utility/helper";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");

  const validate = () => {
    if (!validateEmail(email)) {
      toast.error("Enter a valid email address.");
    } else {
      navigate("/verify-otp", {
        state: { type: "otp", email, send: true, goToCreate: false }, // refers to the send to send an email upon getting to the verify otp page
      });
    }
  };
  return (
    <>
      <div className="form-wrapper">
        <div className="app-form">
          <div className="app-form-sidebar">
            <div className="sidebar-sign-logo">
              <img src="/images/sign-logo.svg" alt="" />
            </div>
            <div className="sign_sidebar_text">
              <h1>
                The Easiest Way to Create Events and Sell More Tickets Online
              </h1>
            </div>
          </div>
          <div className="app-form-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-10">
                  <div className="app-top-items">
                    <Link to="/">
                      <div className="sign-logo" id="logo">
                        <img src="/images/logo.svg" alt="" />
                        <img
                          className="logo-inverse"
                          src="/images/dark-logo.svg"
                          alt=""
                        />
                      </div>
                    </Link>
                    <div className="app-top-right-link">
                      <Link className="sidebar-register-link" to="/signin">
                        <i className="fa-regular fa-circle-left me-2"></i>Back
                        to sign in
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-7">
                  <div className="registration">
                    <form>
                      <h2 className="registration-title">Forgot Password</h2>
                      <div className="form-group mt-5">
                        <label className="form-label">Your Email*</label>
                        <input
                          className="form-control h_50"
                          type="email"
                          placeholder="Enter your email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <button
                        className="main-btn btn-hover w-100 mt-4 transition-all hover:text-white"
                        onClick={(e) => {
                          e.preventDefault();
                          validate();
                        }}
                      >
                        Reset Password
                      </button>
                    </form>
                    <div className="new-sign-link">
                      <Link className="signup-link" to="/signin">
                        <i className="fa-regular fa-circle-left me-2"></i>Back
                        to sign in
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright-footer">
              © 2024, Usepuplar. All rights reserved. Powered by Akawo Synergy
              Limited
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
