import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { RootState } from "../store";
import { FirstLetterUppercase } from "../utility/helper";
import { useDispatch } from "react-redux";
import { SET_LOADER } from "../store/formSlice";
import { logout } from "../api/authentication";
import { toast } from "react-toastify";

const Layout = () => {
  const { pathname } = useLocation();
  const { selectedOrg, orgProfile } = useSelector(
    (state: RootState) => state.org
  );
  const dispatch = useDispatch();
  return (
    <>
      {/* <!-- Header Start--> */}
      <header className="header">
        <div className="header-inner">
          <nav className="navbar navbar-expand-lg bg-barren barren-head navbar fixed-top justify-content-sm-start pt-0 pb-0 ps-lg-0 pe-2">
            <div className="container-fluid ps-0">
              <button type="button" id="toggleMenu" className="toggle_menu">
                <i className="fa-solid fa-bars-staggered"></i>
              </button>
              <button id="collapse_menu" className="collapse_menu me-4">
                <i className="fa-solid fa-bars collapse_menu--icon"></i>
                <span className="collapse_menu--label"></span>
              </button>
              <button
                className="navbar-toggler order-3 ms-2 pe-0"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
              >
                <span className="navbar-toggler-icon">
                  <i className="fa-solid fa-bars"></i>
                </span>
              </button>
              <a
                className="navbar-brand order-1 order-lg-0 ml-lg-0 ml-2 me-auto"
                href="/"
              >
                <div className="res-main-logo">
                  <img src="/images/logo-icon.svg" alt="" />
                </div>
                <div className="main-logo" id="logo">
                  <img src="/images/logo.svg" alt="" />
                  <img
                    className="logo-inverse"
                    src="/images/dark-logo.svg"
                    alt=""
                  />
                </div>
              </a>
              <div
                className="offcanvas offcanvas-start"
                tabIndex={-1}
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
              >
                <div className="offcanvas-header">
                  <div className="offcanvas-logo" id="offcanvasNavbarLabel">
                    <img src="/images/logo-icon.svg" alt="" />
                  </div>
                  <button
                    type="button"
                    className="close-btn"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                </div>
                <div className="offcanvas-body">
                  <div className="offcanvas-top-area">
                    <div className="create-bg">
                      <a href="/create-event" className="offcanvas-create-btn">
                        <i className="fa-solid fa-calendar-days"></i>
                        <span>Create Event</span>
                      </a>
                    </div>
                  </div>
                  <ul className="navbar-nav justify-content-end flex-grow-1 pe_5">
                    {/* <li className="nav-item">
                      <a className="nav-link" href="/">
                        <i className="fa-solid fa-right-left me-2"></i>My Home
                      </a>
                    </li> */}
                    <li className="nav-item">
                      <a className="nav-link" href="/event">
                        <i className="fa-solid fa-compass me-2"></i>Explore
                        Events
                      </a>
                    </li>
                  </ul>
                  <div className="offcanvas-footer">
                    <div className="offcanvas-social">
                      <h5>Follow Us</h5>
                      <ul className="social-links">
                        <li>
                          <a
                            href="https://facebook.com/"
                            target="_black"
                            rel="noreferrer"
                            className="social-link"
                          >
                            <i className="fab fa-facebook-square"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://instagram.com/"
                            target="_black"
                            rel="noreferrer"
                            className="social-link"
                          >
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/"
                            target="_black"
                            rel="noreferrer"
                            className="social-link"
                          >
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://linkedin.com/"
                            target="_black"
                            rel="noreferrer"
                            className="social-link"
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://youtube.com/"
                            target="_black"
                            rel="noreferrer"
                            className="social-link"
                          >
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-header order-2">
                <ul className="align-self-stretch">
                  <li>
                    <a href="/create-event" className="create-btn btn-hover">
                      <i className="fa-solid fa-calendar-days"></i>
                      <span>Create Event</span>
                    </a>
                  </li>
                  <li className="dropdown account-dropdown order-3">
                    <Link
                      to="#"
                      className="account-link flex items-center"
                      role="button"
                      id="accountClick"
                      data-bs-auto-close="outside"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={orgProfile?.image?.url} alt="" />
                      <i className="fas fa-caret-down arrow-icon"></i>
                    </Link>
                    <ul
                      className="dropdown-menu dropdown-menu-account dropdown-menu-end"
                      aria-labelledby="accountClick"
                    >
                      <li>
                        <div className="dropdown-account-header">
                          <div className="account-holder-avatar flex justify-center">
                            <img src={orgProfile?.image?.url} alt="" />
                          </div>
                          <h5>
                            {FirstLetterUppercase(orgProfile?.name || "")}
                          </h5>
                          <p>{FirstLetterUppercase(selectedOrg?.role || "")}</p>
                        </div>
                      </li>
                      <li className="profile-link">
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(SET_LOADER(true));
                            logout(
                              (response) => {
                                dispatch(SET_LOADER(false));
                                toast.success(response.message);
                              },
                              () => dispatch(SET_LOADER(false))
                            );
                          }}
                          className="link-item"
                        >
                          Sign Out
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div className="night_mode_switch__btn">
                      <div id="night-mode" className="fas fa-moon fa-sun"></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className="overlay"></div>
        </div>
      </header>
      {/* <!-- Header End--> */}
      {/* <!-- Left Sidebar Start --> */}
      <nav className="vertical_nav">
        <div className="left_section menu_left" id="js-menu">
          <div className="left_section">
            <ul>
              <li className="menu--item">
                <a
                  href="/"
                  className={`menu--link ${pathname === "/" && "active"}`}
                  title="Dashboard"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                >
                  <i className="fa-solid fa-gauge menu--icon"></i>
                  <span className="menu--label">Dashboard</span>
                </a>
              </li>
              <li className="menu--item">
                <a
                  href="/event"
                  className={`menu--link ${pathname === "/event" && "active"}`}
                  title="Events"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                >
                  <i className="fa-solid fa-calendar-days menu--icon"></i>
                  <span className="menu--label">Events</span>
                </a>
              </li>
              {/* <li className="menu--item">
                <a
                  href="/payout"
                  className={`menu--link ${pathname === "/payout" && "active"}`}
                  title="Payment Method"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                >
                  <i className="fa-solid fa-credit-card menu--icon"></i>
                  <span className="menu--label">Payment Method</span>
                </a>
              </li> */}
              <li className="menu--item">
                <a
                  href="/order"
                  className={`menu--link ${pathname === "/order" && "active"}`}
                  title="Orders"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                >
                  <i className="fa-solid fa-chart-pie menu--icon"></i>
                  <span className="menu--label">Orders</span>
                </a>
              </li>
              <li className="menu--item">
                <a
                  href="/payout"
                  className={`menu--link ${pathname === "/payout" && "active"}`}
                  title="Payouts"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                >
                  <i className="fa-solid fa-money-bill-trend-up menu--icon"></i>
                  <span className="menu--label">Payouts</span>
                </a>
              </li>
              <li className="menu--item">
                <a
                  href="/profile"
                  className={`menu--link ${
                    pathname === "/profile" && "active"
                  }`}
                  title="About"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                >
                  <i className="fa-solid fa-circle-info menu--icon"></i>
                  <span className="menu--label">About</span>
                </a>
              </li>
              <li className="menu--item">
                <a
                  href="/team"
                  className={`menu--link team-lock ${
                    pathname === "/team" && "active"
                  }`}
                  title="My Team"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                >
                  <i className="fa-solid fa-user-group menu--icon"></i>
                  <span className="menu--label">My Team</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* <!-- Left Sidebar End --> */}
    </>
  );
};

export default Layout;
