import React from "react";
import Layout from "../../components/layout";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getOrgOrders, getOrgTransactions } from "../../api/organisation";
import { useOptimizedEffect } from "../../hooks/useOptimizedEffect";
import { RootState } from "../../store";
import { SET_LOADER } from "../../store/formSlice";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/bootstrap.css";
import ReactLoading from "react-loading";
import {
  FirstLetterUppercase,
  FirstLetterUppercaseWord,
} from "../../utility/helper";
import moment from "moment";
import { getEventDetail } from "../../api/event";

const ViewOrder = ({ detail }: { detail: any }) => {
  const [eventDetail, setEventDetail] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (detail?.event_id?._id) {
      setLoading(true);
      getEventDetail(
        detail.event_id._id,
        (response) => {
          setLoading(false);
          setEventDetail(response.data);
        },
        () => setLoading(false)
      );
    }
  }, [detail?.event_id?._id]);
  return (
    <>
      {/* <!-- Organisation Profile Update Model Start--> */}
      <div
        className="modal fade"
        id="order-profile-update-pop"
        tabIndex={-1}
        aria-labelledby="orderUpdatepopLabel"
        aria-hidden="false"
      >
        <div className="modal-dialog modal-medium-2 modal-dialog-scrollable modal-dialog-centered modal-sm-height modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="orgProfileUpdatepopLabel">
                Order Details
              </h5>
              <button
                type="button"
                className="close-model-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="uil uil-multiply"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="model-content main-form pt-4">
                {loading ? (
                  <div className="flex justify-center items-center w-full h-full">
                    <ReactLoading
                      type={"spin"}
                      color={"#004f5d"}
                      height={25}
                      width={25}
                    />
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-lg-12 col-md-12 flex flex-col items-center">
                      <p>
                        <i className="fa-solid fa-calendar-day me-2"></i>
                        Event holds on{" "}
                        {moment(eventDetail?.event_date).format(
                          "ddd, MMM DD, YYYY"
                        )}{" "}
                        {eventDetail?.event_time}
                      </p>
                      <p>
                        <i className="fa-solid fa-location-dot me-2"></i>
                        {FirstLetterUppercaseWord(
                          eventDetail?.location.line1 || ""
                        )}{" "}
                        {FirstLetterUppercaseWord(
                          eventDetail?.location.city || ""
                        )}{" "}
                        {FirstLetterUppercaseWord(
                          eventDetail?.location.state || ""
                        )}{" "}
                        {FirstLetterUppercaseWord(
                          eventDetail?.location.country || ""
                        )}
                      </p>
                      {detail?.tickets.map((item: any, key: number) => (
                        <p key={key.toString()}>
                          <i className="fa-solid fa-ticket fa-rotate-90 transform rotate-[-45deg] me-2"></i>
                          {item.quantity} x{" "}
                          {FirstLetterUppercaseWord(item.ticket_name || "")}
                        </p>
                      ))}
                      <img
                        src={detail?.image_url}
                        alt=""
                        className="min-w-[200px] h-[150px] object-cover my-3"
                      />
                      <div className="border-t-[0.5px] border-gray-300 py-2 flex justify-between items-center w-full">
                        <p>Event Name</p>
                        <p>
                          {FirstLetterUppercaseWord(eventDetail?.name || "")}
                        </p>
                      </div>
                      <div className="border-t-[0.5px] border-gray-300 py-2 flex justify-between items-center w-full">
                        <p>Reference</p>
                        <p>{detail?._id}</p>
                      </div>
                      <div className="border-t-[0.5px] border-gray-300 py-2 flex justify-between items-center w-full">
                        <p>Total Amount</p>
                        <p>₦ {detail?.total_price.toLocaleString()}</p>
                      </div>
                      <div className="border-t-[0.5px] border-gray-300 py-2 flex justify-between items-center w-full">
                        <p>Amount Paid</p>
                        <p>₦ {detail?.paid.toLocaleString()}</p>
                      </div>
                      <div className="border-t-[0.5px] border-gray-300 py-2 flex justify-between items-center w-full">
                        <p>Status</p>
                        <p>{FirstLetterUppercaseWord(detail?.status || "")}</p>
                      </div>
                      <div className="border-t-[0.5px] border-gray-300 py-2 flex justify-between items-center w-full">
                        <p>Payment Method</p>
                        <p>
                          {FirstLetterUppercaseWord(
                            detail?.payment_method || ""
                          )}
                        </p>
                      </div>
                      <div className="border-t-[0.5px] border-gray-300 py-2 flex justify-between items-center w-full">
                        <p>Booked Date</p>
                        <p>
                          {moment(detail?.createdAt).format(
                            "ddd, MMM DD, YYYY hh:mm A"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                className="main-btn w-full btn-hover h_40 transition-all text-[#004F5D] hover:text-white"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Organisation Profile Update Model End--> */}
    </>
  );
};

const Report = () => {
  const [orders, setOrders] = React.useState<any>(null);
  const [page, setPage] = React.useState<number>(1);
  const { selectedOrg } = useSelector((state: RootState) => state.org);
  const [details, setDetails] = React.useState<any>(null);
  const dispatch = useDispatch();

  useOptimizedEffect(() => {
    dispatch(SET_LOADER(true));
    getOrgOrders(
      selectedOrg?.organization_id?._id || "",
      page,
      (response) => {
        dispatch(SET_LOADER(false));
        setOrders(response.data);
        console.log(response.data);
      },
      () => dispatch(SET_LOADER(false))
    );
  });
  return (
    <>
      <ViewOrder detail={details} />
      <Layout />
      {/* <!-- Body Start --> */}
      <div className="wrapper wrapper-body">
        <div className="dashboard-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="d-main-title">
                  <h3>
                    <i className="fa-solid fa-chart-pie me-3"></i>Orders
                  </h3>
                </div>
              </div>
              <div className="col-md-12">
                <div className="main-card mt-5">
                  <div className="dashboard-wrap-content p-4">
                    <div className="d-md-flex flex-wrap align-items-center">
                      <div className="dashboard-date-wrap mt-4">
                        <div className="form-group">
                          <div className="relative-input position-relative">
                            <input
                              className="form-control h_40"
                              type="text"
                              placeholder="Search by name"
                              value=""
                            />
                            <i className="uil uil-search"></i>
                          </div>
                        </div>
                      </div>
                      <div className="rs ms-auto mt-4 mt_r4">
                        <Link
                          to="#"
                          className="pe-4 w-100 ps-4 text-center co-main-btn h_40 d-inline-block"
                        >
                          <i className="uil uil-search me-3"></i>
                          Search
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="event-list">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="orders-tab"
                      role="tabpanel"
                    >
                      <div className="table-card mt-4">
                        <div className="main-table">
                          <div className="table-responsive">
                            <table className="table">
                              <thead className="thead-dark">
                                <tr>
                                  <th scope="col">ID</th>
                                  <th scope="col">Name</th>
                                  <th scope="col">Event Name</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Total</th>
                                  {/* <th scope="col">Action</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {orders?.docs.map((item: any, key: number) => (
                                  <>
                                    <tr
                                      data-bs-toggle="modal"
                                      data-bs-target="#order-profile-update-pop"
                                      onClick={() => setDetails(item)}
                                      key={key.toString()}
                                    >
                                      <td>{item._id.substr(0, 4)}</td>
                                      <td>
                                        {FirstLetterUppercase(
                                          item?.user_id?.first_name || ""
                                        )}{" "}
                                        {FirstLetterUppercase(
                                          item?.user_id?.last_name || ""
                                        )}
                                      </td>
                                      <td>{item?.event_id?.name || ""}</td>
                                      <td>
                                        {item?.tickets.reduce(
                                          (a: any, b: any) => {
                                            return a + b.quantity;
                                          },
                                          0
                                        )}
                                      </td>
                                      <td>
                                        {moment(item.created_at).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td>
                                        {/* <span className="status-circle green-circle"></span> */}
                                        {FirstLetterUppercase(item.status)}
                                      </td>
                                      <td>
                                        ₦{(item?.total_price).toLocaleString()}
                                      </td>
                                      {/* <td className="text-primary font-bold cursor-pointer">
                                        Confirm
                                      </td> */}
                                    </tr>
                                  </>
                                ))}
                              </tbody>
                            </table>
                            <ResponsivePagination
                              current={page}
                              total={orders?.totalPage || 1}
                              onPageChange={(current) => setPage(current)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Body End --> */}
    </>
  );
};

export default Report;
