import React from "react";
import { Link } from "react-router-dom";
import { LineChart } from "../../components/lineChart";
import Layout from "../../components/layout";
import { RootState } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import {
  SET_ORG_PROFILE,
  SET_SELECTED_EVENT,
  SET_SELECTED_ORG,
} from "../../store/orgSlice";
import { FirstLetterUppercase } from "../../utility/helper";
import { SET_LOADER } from "../../store/formSlice";
import { getOrgProfile } from "../../api/organisation";
import { IOrg } from "../../store/authSlice";
import { getEventStats } from "../../api/event";

const Dashboard = () => {
  const { selectedOrg, orgProfile, selectedEvent, orgEvents } = useSelector(
    (state: RootState) => state.org
  );
  const { orgData, userData } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const [eventStats, setEventStats] = React.useState<{
    total_orders: number;
    tickets_sold: number;
    revenu: number;
  } | null>(null);

  React.useEffect(() => {}, [selectedOrg?._id]);

  return (
    <>
      <Layout />
      {/* <!-- Body Start --> */}
      <div className="wrapper wrapper-body">
        <div className="dashboard-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="d-main-title">
                  <h3>
                    <i className="fa-solid fa-gauge me-3"></i>Dashboard
                  </h3>
                </div>
              </div>
              <div className="col-md-12">
                <div className="main-card add-organisation-card p-4 mt-5">
                  <div className="ocard-left">
                    <div className="ocard-avatar">
                      <img src={orgProfile?.image?.url} alt="" />
                    </div>
                    <div className="ocard-name">
                      <h4>{FirstLetterUppercase(orgProfile?.name || "")}</h4>
                      <span>
                        {FirstLetterUppercase(selectedOrg?.role || "")}
                      </span>
                    </div>
                  </div>
                  <div className="ocard-right">
                    {/* <label className="form-label">Select Organization</label> */}
                    <div className="dropdown dropdown-text event-list-dropdown">
                      <button
                        className="dropdown-toggle event-list-dropdown"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span>{selectedOrg?.organization_id?.name}</span>
                      </button>
                      <ul className="dropdown-menu">
                        {orgData?.map((item: IOrg) => (
                          <li key={item._id}>
                            <Link
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(SET_SELECTED_ORG(item));
                                dispatch(SET_LOADER(true));
                                getOrgProfile(
                                  item.organization_id?._id as string,
                                  (response: any) => {
                                    dispatch(SET_LOADER(false));
                                    dispatch(SET_ORG_PROFILE(response.data));
                                  },
                                  () => dispatch(SET_LOADER(false))
                                );
                              }}
                              className="dropdown-item"
                              to="#"
                            >
                              {item?.organization_id?.name}
                            </Link>
                          </li>
                        ))}
                        <li>
                          <Link className="dropdown-item" to="/create-org">
                            Create Organization
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="main-card mt-4">
                  <div className="dashboard-wrap-content">
                    <div className="d-flex flex-wrap justify-content-between align-items-center p-4">
                      <div className="dashboard-date-wrap d-flex flex-wrap justify-content-between align-items-center"></div>
                      <div className="rs">
                        <div className="dropdown dropdown-text event-list-dropdown">
                          <button
                            className="dropdown-toggle event-list-dropdown"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span>{selectedEvent?.name}</span>
                          </button>
                          <ul className="dropdown-menu">
                            {orgEvents?.map((item: any) => (
                              <li key={item._id}>
                                <Link
                                  onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(SET_SELECTED_EVENT(item));
                                    dispatch(SET_LOADER(true));
                                    getEventStats(
                                      item._id,
                                      (response: any) => {
                                        dispatch(SET_LOADER(false));
                                        setEventStats(response.data);
                                      },
                                      () => dispatch(SET_LOADER(false))
                                    );
                                  }}
                                  className="dropdown-item"
                                  to="#"
                                >
                                  {item?.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="dashboard-report-content">
                      <div className="row">
                        <div className="col-xl-3 col-lg-6 col-md-6">
                          <div className="dashboard-report-card purple">
                            <div className="card-content">
                              <div className="card-content">
                                <span className="card-title fs-6">
                                  Revenue (AUD)
                                </span>
                                <span className="card-sub-title fs-3">
                                  {eventStats?.revenu.toLocaleString() || 0}
                                </span>
                              </div>
                              <div className="card-media">
                                <i className="fa-solid fa-money-bill"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6">
                          <div className="dashboard-report-card red">
                            <div className="card-content">
                              <div className="card-content">
                                <span className="card-title fs-6">Orders</span>
                                <span className="card-sub-title fs-3">
                                  {eventStats?.total_orders.toLocaleString() ||
                                    0}
                                </span>
                              </div>
                              <div className="card-media">
                                <i className="fa-solid fa-box"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6">
                          <div className="dashboard-report-card info">
                            <div className="card-content">
                              <div className="card-content">
                                <span className="card-title fs-6">
                                  Ticket Sold
                                </span>
                                <span className="card-sub-title fs-3">
                                  {eventStats?.tickets_sold.toLocaleString() ||
                                    0}
                                </span>
                              </div>
                              <div className="card-media">
                                <i className="fa-solid fa-ticket"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6">
                          <div className="dashboard-report-card success">
                            <div className="card-content">
                              <div className="card-content">
                                <span className="card-title fs-6">
                                  Ticket Available
                                </span>
                                <span className="card-sub-title fs-3">
                                  {eventStats?.tickets_sold.toLocaleString() ||
                                    0}
                                </span>
                              </div>
                              <div className="card-media">
                                <i className="fa-solid fa-ticket"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-card mt-4">
                  <div className="d-flex flex-wrap justify-content-between align-items-center border_bottom p-4">
                    <div className="dashboard-date-wrap d-flex flex-wrap justify-content-between align-items-center">
                      <div className="select-graphic-category">
                        <small className="mt-4">
                          See the graphical representation below
                        </small>
                      </div>
                    </div>
                    <div className="rs">
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic radio toggle button group"
                      >
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id="btnradio1"
                        />
                        <label
                          className="btn btn-outline-primary"
                          htmlFor="btnradio1"
                        >
                          Monthly
                        </label>
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id="btnradio2"
                          checked
                        />
                        <label
                          className="btn btn-outline-primary"
                          htmlFor="btnradio2"
                        >
                          Weekly
                        </label>
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id="btnradio3"
                        />
                        <label
                          className="btn btn-outline-primary"
                          htmlFor="btnradio3"
                        >
                          Daily
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="item-analytics-content p-4 ps-1 pb-2">
                    <div id="views-graphic" className="w-full h-[300px]">
                      <LineChart
                        data={[
                          { id: 1, label: "Mon", value: 300 },
                          { id: 2, label: "Tue", value: 600 },
                          { id: 3, label: "Wed", value: 500 },
                          { id: 4, label: "Thurs", value: 700 },
                          { id: 5, label: "Fri", value: 200 },
                          { id: 6, label: "Sat", value: 400 },
                          { id: 7, label: "Sun", value: 700 },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
