import React from "react";
import { toast } from "react-toastify";
import {
  VALIDATE_USER_DATA,
  convertSecondsToHoursAndMinutes,
} from "../utility/helper";
import { AiOutlineCheck, AiOutlinePlus } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { SET_LOADER } from "../store/formSlice";
import { createEvent, editEvent } from "../api/event";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import moment from "moment";
import { useParams } from "react-router-dom";

const EditEventSection = ({
  onSubmit,
  eventDetail,
}: {
  onSubmit: () => void;
  eventDetail: any;
}) => {
  //   console.log(moment(eventDetail?.event_date).format("MM/DD/YYYY"));
  const { id } = useParams();
  const [name, setName] = React.useState<string>("");
  const [category, setCategory] = React.useState<string>("");
  const [duration, setDuration] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [event_date, setEventDate] = React.useState<string>("");
  const [event_time, setEventTime] = React.useState<string>("");
  const [line1, setLine1] = React.useState<string>("");
  const [city, setCity] = React.useState<string>("");
  const [postal_code, setPostalCode] = React.useState<string>("");
  const [country, setCountry] = React.useState<string>("");
  const [state, setState] = React.useState<string>("");
  const [image, setImage] = React.useState<File | null>(null);

  const dispatch = useDispatch();
  const { selectedOrg } = useSelector((state: RootState) => state.org);

  React.useEffect(() => {
    if (eventDetail) {
      setName(eventDetail?.name || "");
      setCategory(eventDetail?.category.toLowerCase() || "");
      setDuration(eventDetail?.duration || "");
      setDescription(eventDetail?.description || "");
      setEventDate(
        eventDetail?.event_date
          ? moment(eventDetail?.event_date).format("YYYY-MM-DD")
          : ""
      );
      setEventTime(eventDetail?.event_time || "");
      setLine1(eventDetail?.location.line1 || "");
      setCity(eventDetail?.location.city || "");
      setPostalCode(eventDetail?.location.postal_code || "");
      setCountry(eventDetail?.location.country || "");
      setState(eventDetail?.location.state || "");
    }
  }, [eventDetail]);

  const validate = () => {
    VALIDATE_USER_DATA(
      {
        name,
        category,
        event_date,
        event_time,
        duration,
        description,
        line1,
        city,
        state,
        country,
        postal_code,
      },
      (e) => {
        dispatch(SET_LOADER(true));
        editEvent(
          id || "",
          {
            name,
            category,
            event_date,
            event_time,
            duration,
            description,
            line1,
            city,
            state,
            country,
            postal_code,
            image,
          },
          (response) => {
            dispatch(SET_LOADER(false));
            onSubmit();
          },
          () => dispatch(SET_LOADER(false))
        );
      }
    );
  };
  return (
    <>
      <div className="step-content">
        <div className="tab-from-content">
          <div className="main-card">
            <div className="bp-title">
              <h4>
                <i className="fa-solid fa-circle-info step_icon me-3"></i>
                Details
              </h4>
            </div>
            <div className="p-4 bp-form main-form">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="form-group border_bottom pb_30">
                    <label className="form-label fs-16">
                      Give your event a name.*
                    </label>
                    <p className="mt-2 d-block fs-14 mb-3">
                      See how your name appears on the event page and a list of
                      all places where your event name will be used
                    </p>
                    <input
                      className="form-control h_50 focus:!border-primary"
                      type="text"
                      placeholder="Enter event name here"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group border_bottom pt_30 pb_30">
                    <label className="form-label fs-16">
                      Choose a category for your event.*
                    </label>
                    <p className="mt-2 d-block fs-14 mb-3">
                      Choosing relevant categories helps to improve the
                      discoverability of your event.
                    </p>
                    <select
                      className="form-control h_50 focus:!border-primary"
                      title="Select category"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="">Select a category for your event</option>
                      <option value="arts">Arts</option>
                      <option value="business">Business</option>
                      <option value="coaching and consulting">
                        Coaching and Consulting
                      </option>
                      <option value="community and culture">
                        Community and Culture
                      </option>
                      <option value="family and friends">
                        Family and Friends
                      </option>
                      <option value="fashion and beauty">
                        Fashion and Beauty
                      </option>

                      <option value="food and drink">Food and Drink</option>
                      <option value="health and wellbeing">
                        Health and Wellbeing
                      </option>
                      <option value="hobbies and interest">
                        Hobbies and Interest
                      </option>
                      <option value="music and theater">
                        Music and Theater
                      </option>
                      <option value="religion and spirituality">
                        Religion and Spirituality
                      </option>
                      <option value="science and technology">
                        Science and Technology
                      </option>
                      <option value="sports and fitness">
                        Sports and Fitness
                      </option>
                      <option value="travel and outdoor">
                        Travel and Outdoor
                      </option>
                      <option value="visua arts">Visua Arts</option>
                      <option value="others">Others</option>
                    </select>
                  </div>
                  <div className="form-group border_bottom pt_30 pb_30">
                    <label className="form-label fs-16">
                      When is your event?*
                    </label>
                    <p className="mt-2 fs-14 d-block mb-3">
                      Tell your attendees when your event starts so they can get
                      ready to attend.
                    </p>
                    <div className="row g-2">
                      <div className="col-md-6">
                        <label className="form-label mt-3 fs-6">
                          Event Date.*
                        </label>
                        <input
                          className="form-control h_50 focus:!border-primary"
                          data-language="en"
                          type="date"
                          placeholder="MM/DD/YYYY"
                          value={event_date}
                          onChange={(e) => setEventDate(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="row g-2">
                          <div className="col-md-6">
                            <div className="clock-icon">
                              <label className="form-label mt-3 fs-6">
                                Time
                              </label>
                              <input
                                className="form-control h_50 focus:!border-primary"
                                type="time"
                                placeholder=""
                                value={event_time}
                                onChange={(e) => setEventTime(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label className="form-label mt-3 fs-6">
                              Duration
                            </label>
                            <select
                              value={duration}
                              onChange={(e) => setDuration(e.target.value)}
                              className="form-control h_50 focus:!border-primary"
                            >
                              <option value="15m">15m</option>
                              <option value="30m">30m</option>
                              <option value="45m">45m</option>
                              <option value="1h" selected>
                                1h
                              </option>
                              <option value="1h 15m">1h 15m</option>
                              <option value="1h 30m">1h 30m</option>
                              <option value="1h 45m">1h 45m</option>
                              <option value="2h">2h</option>
                              <option value="2h 15m">2h 15m</option>
                              <option value="2h 30m">2h 30m</option>
                              <option value="2h 45m">2h 45m</option>
                              <option value="3h">3h</option>
                              <option value="3h 15m">3h 15m</option>
                              <option value="3h 30m">3h 30m</option>
                              <option value="3h 45m">3h 45m</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group pt_30 pb_30">
                    <label className="form-label fs-16">
                      Add a few images to your event banner.
                    </label>
                    <p className="mt-2 fs-14 d-block mb-3 pe_right">
                      Upload colorful and vibrant images as the banner for your
                      event! See how beautiful images help your event details
                      page.
                    </p>
                    <div className="content-holder mt-4">
                      <div className="default-event-thumb bg-[#F9F9F9BF]">
                        <div className="default-event-thumb-btn">
                          <div className="thumb-change-btn">
                            {/* <input  type="file" id="thumb-img" /> */}
                            <input
                              type="file"
                              onChange={(e) =>
                                setImage(e.target.files && e.target.files[0])
                              }
                              id="thumb-img"
                            />
                            <label htmlFor="thumb-img">Change Image</label>
                          </div>
                        </div>
                        {/* <img src="/images/banners/custom-img.jpg" alt="" /> */}
                        <div className="w-full h-full ">
                          <div className="w-full rounded-md mx-auto h-full bg-[#F9F9F9BF] mt-2 flex justify-center items-center relative">
                            <div className="flex flex-col items-center">
                              <div className="w-[50px] h-[50px] flex justify-center items-center bg-white rounded-full shadow-md">
                                {image ? (
                                  <AiOutlineCheck color="#004F5D" size={25} />
                                ) : (
                                  <AiOutlinePlus color="#004F5D" size={25} />
                                )}
                              </div>
                              <p className="font-Nunito text-darkGrey text-[14px] mt-[10px]">
                                {image
                                  ? "Image uploaded Successfully"
                                  : "Upload Image or Banner"}
                              </p>
                            </div>
                            <input
                              type="file"
                              onChange={(e) =>
                                setImage(e.target.files && e.target.files[0])
                              }
                              className="absolute !top-0 left-0 w-full h-full opacity-0"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group border_bottom pb_30">
                    <label className="form-label fs-16">
                      Please describe your event.
                    </label>
                    <p className="mt-2 fs-14 d-block mb-3">
                      Write a few words below to describe your event and provide
                      any extra information such as schedules, itinerary or any
                      special instructions required to attend your event.
                    </p>
                    <div className="text-editor mt-4">
                      <textarea
                        rows={7}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="form-control py-3 px-3 focus:!border-primary"
                      ></textarea>
                    </div>
                  </div>
                  <div className="form-group pt_30 pb-2">
                    <label className="form-label fs-16">
                      Where is your event taking place? *
                    </label>
                    <p className="mt-2 fs-14 d-block mb-3">
                      Add a venue to your event to tell your attendees where to
                      join the event.
                    </p>
                    <div className="stepper-data-set">
                      <div className="content-holder template-selector">
                        <div className="row g-4">
                          <div className="col-md-12">
                            <div className="form-group mt-1">
                              <label className="form-label fs-6">Venue*</label>
                              <input
                                className="form-control h_50 focus:!border-primary"
                                type="text"
                                placeholder=""
                                value={line1}
                                onChange={(e) => setLine1(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group main-form mt-1">
                              <label className="form-label">Country*</label>
                              <input
                                className="form-control h_50 focus:!border-primary"
                                type="text"
                                placeholder=""
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mt-1">
                              <label className="form-label">State*</label>
                              <input
                                className="form-control h_50 focus:!border-primary"
                                type="text"
                                placeholder=""
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="form-group mt-1">
                              <label className="form-label">City/Suburb*</label>
                              <input
                                className="form-control h_50 focus:!border-primary"
                                type="text"
                                placeholder=""
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="form-group mt-1">
                              <label className="form-label">
                                Zip/Post Code*
                              </label>
                              <input
                                className="form-control h_50 focus:!border-primary"
                                type="text"
                                placeholder=""
                                value={postal_code}
                                onChange={(e) => setPostalCode(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="step-footer step-tab-pager mt-4">
        <button
          className="btn btn-default btn-hover steps_btn"
          onClick={validate}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default EditEventSection;
