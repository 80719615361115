import React from "react";
import { Link } from "react-router-dom";
import TicketCard from "./ticketCard";
import { VALIDATE_USER_DATA } from "../utility/helper";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { SET_LOADER } from "../store/formSlice";
import { createEventTicket } from "../api/event";

export const CreateTicket = ({
  setTicketList,
  setTicketModal,
  ticketModal,
  event_id,
}: {
  event_id: string;
  setTicketList: React.Dispatch<React.SetStateAction<any[]>>;
  setTicketModal: React.Dispatch<React.SetStateAction<boolean>>;
  ticketModal: boolean;
}) => {
  const [name, setName] = React.useState<string>("");
  const [price, setPrice] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [total, setTotal] = React.useState<string>("");
  const [expiration_date, setExpirationDate] = React.useState<string>("");
  const [percentage, setPercentage] = React.useState<string>("");
  const [apply_discount, setApplyDiscount] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const validate = () => {
    VALIDATE_USER_DATA({ name, price, description, total }, (e) => {
      if (apply_discount) {
        if (percentage === "") {
          toast.error("Enter percentage of discount");
        } else if (expiration_date === "") {
          toast.error("Enter discount end date.");
        } else {
          dispatch(SET_LOADER(true));
          createEventTicket(
            apply_discount
              ? {
                  name,
                  price: Number(price),
                  description,
                  total: Number(total),
                  apply_discount,
                  event_id,
                  is_active: true,
                  is_unlimited: false,
                  discount: {
                    percent: Number(percentage),
                    expiration_date,
                  },
                }
              : {
                  name,
                  price: Number(price),
                  description,
                  total: Number(total),
                  apply_discount,
                  event_id,
                  is_active: true,
                  is_unlimited: false,
                },
            (response: any) => {
              dispatch(SET_LOADER(false));
              console.log(response.data);
              setTicketList((prev) => [...prev, response.data]);
            },
            () => dispatch(SET_LOADER(false))
          );
        }
      } else {
        dispatch(SET_LOADER(true));
        createEventTicket(
          apply_discount
            ? {
                name,
                price: Number(price),
                description,
                total: Number(total),
                apply_discount,
                event_id,
                is_active: true,
                is_unlimited: false,
                discount: {
                  percent: Number(percentage),
                  expiration_date,
                },
              }
            : {
                name,
                price: Number(price),
                description,
                total: Number(total),
                apply_discount,
                event_id,
                is_active: true,
                is_unlimited: false,
              },
          (response: any) => {
            dispatch(SET_LOADER(false));
            setTicketList((prev) => [...prev, response.data]);
          },
          () => dispatch(SET_LOADER(false))
        );
      }
    });
  };
  return (
    <>
      <div
        className="modal fade"
        id="singleTicketModal"
        tabIndex={-1}
        aria-labelledby="singleTicketModalLabel"
        aria-hidden="false"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="singleTicketModalLabel">
                Create Ticket
              </h5>
              <button
                type="button"
                className="close-model-btn"
                data-bs-target="#aboutModal"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="uil uil-multiply"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="model-content main-form">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">Ticket Name*</label>
                      <input
                        className="form-control h_40 focus:!border-primary"
                        type="text"
                        placeholder="Event Ticket Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="form-group mt-4">
                      <label className="form-label">Ticket Price*</label>
                      <input
                        className="form-control h_40 focus:!border-primary"
                        type="number"
                        placeholder="Event Ticket Price"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </div>
                    <div className="form-group mt-4">
                      <label className="form-label">Total Tickets*</label>
                      <input
                        className="form-control h_40 focus:!border-primary"
                        type="number"
                        placeholder="Total Event Ticket"
                        value={total}
                        onChange={(e) => setTotal(e.target.value)}
                      />
                    </div>
                    <div className="form-group mt-4">
                      <label className="form-label mb-2 fs-14">
                        Ticket Description*
                      </label>
                      <textarea
                        className="form-textarea focus:!border-primary"
                        placeholder="Description will go here"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="main-card p-4 mt-4">
                      <div className="form-group">
                        <div className="d-flex align-items-start">
                          <label className="btn-switch m-0 me-3">
                            <input
                              type="checkbox"
                              className=""
                              id="bird-discount"
                              checked={apply_discount}
                              onChange={() => setApplyDiscount(!apply_discount)}
                            />
                            <span className="checkbox-slider"></span>
                          </label>
                          <div className="d-flex flex-column">
                            <label className="color-black mb-1">
                              I want to offer early bird discount.
                            </label>
                            <p className="mt-2 fs-14 d-block mb-3">
                              Enabling this discount lets your attendees get all
                              the regular tickets features at a discounted
                              price.
                            </p>
                          </div>
                        </div>
                        <div
                          className="online-event-discount-wrapper hidden"
                          style={{ display: apply_discount ? "block" : "none" }}
                        >
                          <div className="row g-3">
                            <div className="col-md-6">
                              <label className="form-label mt-3 fs-6">
                                Percent(%)
                              </label>
                              <input
                                className="form-control h_40 focus:!border-primary"
                                type="text"
                                placeholder="0"
                                value={percentage}
                                onChange={(e) => setPercentage(e.target.value)}
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label mt-3 fs-6">
                                Discount ends on*
                              </label>
                              <input
                                className="form-control h_40 focus:!border-primary"
                                type="date"
                                placeholder="MM/DD/YYYY"
                                value={expiration_date}
                                onChange={(e) =>
                                  setExpirationDate(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="co-main-btn min-width btn-hover h_40"
                data-bs-target="#aboutModal"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                data-bs-target="#aboutModal"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                type="button"
                className="main-btn min-width btn-hover h_40 !text-[#004F5D] hover:text-white"
                onClick={(e) => {
                  e.preventDefault();
                  validate();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Create Single Ticket Model End--> */}
    </>
  );
};

export const TicketSection = ({
  ticketList,
  goBack,
  setTicketModal,
  setTicketList,
}: {
  goBack: () => void;
  ticketList: any[];
  setTicketModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTicketList: React.Dispatch<React.SetStateAction<any[]>>;
}) => {
  return (
    <>
      <div className="step-content">
        <div className="tab-from-content">
          <div className="main-card">
            <div className="bp-title">
              <h4>
                <i className="fa-solid fa-ticket step_icon me-3"></i>
                Tickets
              </h4>
            </div>
            <div className="bp-form main-form">
              <div className="p-4 form-group border_bottom pb_30">
                <div className="">
                  <div className="ticket-section">
                    <label className="form-label fs-16">
                      Let's create tickets!
                    </label>
                    <p className="mt-2 fs-14 d-block mb-3 pe_right">
                      Create tickets for your event by clicking on the 'Create
                      Ticket' button below.
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between pt-4 pb-3 full-width">
                    <h3 className="fs-18 mb-0">Tickets</h3>
                    <div className="dropdown dropdown-default dropdown-normal btn-ticket-type-top">
                      <Link
                        className="main-btn btn-hover h_40 pe-4 ps-4"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#singleTicketModal"
                        onClick={(e) => {
                          e.preventDefault();
                          setTicketModal(true);
                        }}
                      >
                        <i className="fa-solid fa-ticket me-2"></i>
                        Create Ticket
                      </Link>
                    </div>
                  </div>
                  {/* WHEN TICKETS ARE EMPTY */}

                  {ticketList.length === 0 ? (
                    <div className="ticket-type-item-empty text-center p_30">
                      <div className="ticket-list-icon mx-auto flex justify-center items-center">
                        <img src="/images/ticket.png" alt="" />
                      </div>
                      <h4 className="color-black mt-4 mb-3 fs-18">
                        You have no tickets yet.
                      </h4>
                      <p className="mb-0">
                        You have not created a ticket yet. Please click the
                        button above to create your event ticket.
                      </p>
                    </div>
                  ) : (
                    <div className="ticket-type-item-list mt-4">
                      {ticketList.map((item, key) => (
                        <TicketCard
                          item={item}
                          key={key}
                          setTicketList={setTicketList}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="step-footer step-tab-pager mt-4">
        {ticketList.length > 0 && (
          <button
            onClick={() => {
              window.location.href = "/";
            }}
            className="btn btn-default btn-hover steps_btn md:ml-4"
          >
            Finish
          </button>
        )}
      </div>
    </>
  );
};
